import { Input } from 'antd'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { useIntlFormatter, withPrefix } from 'src/sdk/contexts/Config'
import { v4 as uuid } from 'uuid'
import { HorizontalSpace } from '../layout'
import { Button, ButtonIcon } from './Button'
import IvyIcon from '../icon'
import './InputNumber.less'

export type InputMoneyProps = {
  mode: 'dollars' | 'cents' | 'dollars+cents'
  addonBeforeAmount?: number
  addonAfterAmount?: number
  defaultValue?: number
  value?: string | number
  onChange?: (value?: string | number) => void
}

export const InputMoney: FC<InputMoneyProps> = ({ mode, addonBeforeAmount, addonAfterAmount, value, onChange }) => {
  const { currency } = useIntlFormatter()
  const [currValue, setCurrValue] = useState(value)

  const handleChange = (evt) => {
    const newValue: string = evt.target.value
    if (mode === 'cents' || mode === 'dollars') {
      setCurrValue(newValue.replace(/[^0-9]/g, ''))
    } else {
      setCurrValue(newValue.replace(/[^0-9.]/g, ''))
    }
  }

  const PrefixElem: FC<{ amount: number }> = ({ amount }) => {
    const prefix = currency(amount)
    if (mode === 'cents') {
      return <div>{prefix.replace('.00', '.')}</div>
    }
    return <div>{prefix.replace('.00', '')}</div>
  }

  useEffect(() => {
    onChange && onChange(currValue ? currValue : undefined)
  }, [currValue])

  return (
    <Input
      className={withPrefix('input-money')}
      type={'text'}
      min={0.1}
      max={0.99}
      minLength={mode === 'cents' ? 2 : undefined}
      maxLength={mode === 'cents' ? 2 : undefined}
      placeholder={'00'}
      onChange={handleChange}
      addonBefore={addonBeforeAmount !== undefined && <PrefixElem amount={addonBeforeAmount} />}
      addonAfter={addonAfterAmount && <PrefixElem amount={addonAfterAmount} />}
      value={currValue}
    />
  )
}

export type InputNumberProps = {
  defaultValue?: number
  value?: number
  min?: number
  max?: number | undefined
  onChange?: (value: number) => void
  disabled?: boolean
  immutable?: boolean
  size?: 'small' | 'middle' | 'large'
}

export const InputNumber: FC<InputNumberProps> = ({
  defaultValue = 1,
  min = 0,
  max,
  onChange = (value) => value,
  disabled = false,
  size = 'middle',
  immutable = false,
}) => {
  const [id] = useState(uuid())
  const [currentValue, setCurrentValue] = useState(defaultValue)
  const decreaseDisabled = disabled || currentValue <= min
  const increaseDisabled = disabled || (max ? currentValue >= max : false)

  const className = withPrefix(
    'input-number-custom',
    decreaseDisabled ? 'down-disabled' : '',
    increaseDisabled ? 'up-disabled' : '',
    `input-number-${size}`,
    immutable ? 'input-number-immutable' : '',
  )

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const value = Number(evt.target.value)
    if (value === currentValue) return
    const newValue = max && currentValue > max ? currentValue : currentValue <= min ? min : value
    setCurrentValue(newValue)
    onChange(newValue)
  }

  const handleIncrease = () => {
    const newValue = max && currentValue > max ? currentValue : currentValue + 1
    setCurrentValue(newValue)
    onChange(newValue)
  }

  const handleDecrease = () => {
    const newValue = min && currentValue <= min ? min : currentValue - 1
    setCurrentValue(newValue)
    onChange(newValue)
  }

  return (
    <HorizontalSpace className={className}>
      {!immutable && (
        <Button
          disabled={decreaseDisabled}
          className={withPrefix('input-number-handler', 'input-number-handler-down')}
          icon={<IvyIcon type={'symbol/minus'} />}
          type={'link'}
          onClick={handleDecrease}
        />
      )}

      <Input
        min={min}
        max={max ?? undefined}
        readOnly
        type={'number'}
        name={`quantity-${id}`}
        autoComplete='off'
        step='1'
        className={withPrefix('input-number-input')}
        onChange={handleChange}
        disabled={immutable || disabled}
        value={currentValue}
      />
      {!immutable && (
        <Button
          disabled={increaseDisabled}
          className={withPrefix('input-number-handler', 'input-number-handler-up')}
          icon={<IvyIcon type={'symbol/plus'} />}
          type={'link'}
          onClick={handleIncrease}
        />
      )}
    </HorizontalSpace>
  )
}

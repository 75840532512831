import { BillingRequest, BillingRequestFlow, useGoCardlessDropin } from '@gocardless/react-dropin'
import { ButtonProps, Divider, notification } from 'antd'
import { FC, useEffect, useState } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { useController } from 'rest-hooks'
import { Button } from 'src/sdk/components/form'
import { VerticalSpace } from 'src/sdk/components/layout'
import { MaskLoader } from 'src/sdk/components/loader'
import { usePublicConfig } from 'src/sdk/contexts/Config'
import { useNotification } from 'src/sdk/contexts/Notification'
import { GoCardlessFlow, PlaidResource, StripeResource } from 'src/sdk/datasource/integrations'
import { BankAccount, BankAccountCreateToken } from 'src/sdk/datasource/wallet/bankaccount'
import { CreditCardEntity } from 'src/sdk/datasource/wallet/creditcard'

type AchConnectProps = {
  buttonText?: string
  onClose?: () => void
  includeOr?: 'before' | 'after'
  onSuccess?: (paymentItem: CreditCardEntity | BankAccount) => void
  onError?: () => void
  onLoading?: (loading: boolean) => void
} & Partial<ButtonProps>

const PlaidConnect: FC<AchConnectProps> = ({ buttonText, onClose, ...props }) => {
  const { fetch } = useController()
  const { notifyOnError } = useNotification()
  const [creating, setCreating] = useState(false)
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState<string | null>(null)

  const createBankAccount = (public_token: string, account_id: string) => {
    fetch(
      BankAccount.add(),
      {},
      {
        token: public_token,
        accountId: account_id,
        tokenType: 'plaid',
      },
    )
      .catch(notifyOnError)
      .finally(() => {
        setLoading(false)
        setCreating(false)
      })
  }

  const { open, ready } = usePlaidLink({
    token: token,
    onSuccess: (publicToken, metadata) => {
      setCreating(true)
      metadata.accounts.forEach((account) => {
        createBankAccount(publicToken, account.id)
      })
    },
    onExit: () => {
      setLoading(false)
      onClose && onClose()
    },
  })

  const fetchLink = () => {
    setLoading(true)
    PlaidResource.getLink()
      .then((response) => {
        setToken(response.linkToken)
      })
      .catch((error) => {
        setLoading(false)
        notifyOnError(error)
      })
  }

  useEffect(() => {
    if (ready) {
      open()
    }
  }, [ready, open])

  return (
    <>
      <Button
        block
        style={props.style}
        htmlType={'button'}
        onClick={fetchLink}
        type={'primary'}
        disabled={loading}
        loading={loading}
        {...props}
      >
        {buttonText ? buttonText : 'Connect a Bank Account'}
      </Button>
      <MaskLoader loading={creating} />
    </>
  )
}

const StripeConnect: FC<AchConnectProps> = ({ buttonText, onClose, ...props }) => {
  const { notifyOnError } = useNotification()
  const [creating, setCreating] = useState(false)
  const [loading, setLoading] = useState(false)

  const createSession = () => {
    setLoading(true)
    StripeResource.createSession()
      .then()
      .catch((error) => {
        notifyOnError(error)
      })
  }
  return (
    <>
      <Button
        block
        style={props.style}
        htmlType={'button'}
        onClick={createSession}
        type={'primary'}
        disabled={loading}
        loading={loading}
      >
        {buttonText ? buttonText : 'Connect a Bank Account'}
      </Button>
      <MaskLoader loading={creating} />
    </>
  )
}

const GoCardlessConnect: FC<AchConnectProps> = ({ buttonText, onClose, onSuccess, onError, onLoading, ...props }) => {
  const { fetch } = useController()
  const { notifyOnError } = useNotification()
  const [linkError, setLinkError] = useState(false)
  const [flowId, setFlowId] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [creating, setCreating] = useState(false)
  const [sandbox, setSandbox] = useState(false)

  const { open } = useGoCardlessDropin({
    billingRequestFlowID: flowId ? flowId : '',
    environment: sandbox ? 'sandbox' : 'live',
    onSuccess: (billingRequest: BillingRequest, billingRequestFlow: BillingRequestFlow) => {
      const billingDetails = billingRequest.resources.customer_billing_detail
      fetch(BankAccount.add(), {}, {
        token: billingRequest.id,
        billingDetails: {
          address: billingDetails.address_line1,
          address2: billingDetails.address_line2,
          city: billingDetails.city,
          state: billingDetails.region,
          zipCode: billingDetails.postal_code,
          country: billingDetails.country_code,
        },
        tokenType: 'gocardless',
      } as BankAccountCreateToken)
        .then((account) => {
          notification.success({ message: 'Bank account was successfully added' })
          onSuccess && onSuccess(account)
        })
        .catch((error) => {
          notifyOnError(error)
          onError && onError()
        })
        .finally(() => {
          setLoading(false)
          onLoading && onLoading(false)
          setCreating(false)
        })
    },
    onExit: (error: object | null, metadata: object) => {
      setLoading(false)
      onLoading && onLoading(false)
      setCreating(false)
      onClose && onClose()
    },
  })

  const initConnect = () => {
    if (loading) return
    onLoading && onLoading(true)
    setCreating(true)
    open()
  }

  useEffect(() => {
    async function createFlow() {
      setLoading(true)
      GoCardlessFlow.createLink({
        redirectUri: `${window.location.origin}/account/wallet`,
        exitUri: `${window.location.origin}/account/wallet`,
      })
        .then((response) => {
          setLinkError(false)
          setSandbox(response.sandbox)
          setFlowId(response.id)
        })
        .catch((error) => {
          setLinkError(true)
          notifyOnError(error)
        })
        .finally(() => setLoading(false))
    }
    createFlow()
  }, [])

  return (
    <>
      <Button
        block
        style={props.style}
        htmlType={'button'}
        onClick={initConnect}
        type={'primary'}
        disabled={loading || creating || linkError}
        loading={loading || creating}
      >
        {buttonText ? buttonText : 'Connect a Bank Account'}
      </Button>
    </>
  )
}

const AchConnect: FC<AchConnectProps> = ({ includeOr, ...props }) => {
  const { achProcessor } = usePublicConfig()

  const GetAchIntegration = () => {
    if (achProcessor?.integration?.name) {
      switch (achProcessor?.integration?.name) {
        case 'plaid':
          return <PlaidConnect {...props} />
        default:
          return null
      }
    }
    switch (achProcessor?.name) {
      case 'gocardless':
        return <GoCardlessConnect {...props} />
      default:
        return null
    }
  }
  return achProcessor ? (
    includeOr !== undefined ? (
      includeOr === 'before' ? (
        <VerticalSpace justify={'center'}>
          <Divider>or</Divider>
          <GetAchIntegration />
        </VerticalSpace>
      ) : (
        <VerticalSpace justify={'center'}>
          <GetAchIntegration />
          <Divider>or</Divider>
        </VerticalSpace>
      )
    ) : (
      <GetAchIntegration {...props} />
    )
  ) : null
}

export { AchConnect, PlaidConnect, StripeConnect, GoCardlessConnect }

import { createContext, useContext } from 'react'
import { usePublicConfig } from './Config'

type FeatureProps = {
  hasNomination?: boolean
  hasPersonalDetails?: boolean
  hasContact?: boolean
  hasGuestPass?: boolean
  hasRegistration?: boolean
  hasMemberDirectory: boolean
  hasGuestDirectory?: boolean
  hasSms?: boolean
  hasMessaging: boolean
  hasStripeProcessor: boolean
  editPrimaryInfo?: boolean
}
const defaultFeatures: FeatureProps = {
  hasNomination: false,
  hasPersonalDetails: false,
  hasContact: false,
  hasGuestPass: false,
  hasMemberDirectory: false,
  hasGuestDirectory: false,
  hasSms: false,
  hasMessaging: false,
  hasStripeProcessor: false,
  hasRegistration: false,
  editPrimaryInfo: false,
}

const Feature = createContext(defaultFeatures)

const useFeatureToggles: () => typeof defaultFeatures = () => useContext(Feature)

const FeatureProvider = ({ children }) => {
  const { features, settings, processorId, stripeProcessor, processors } = usePublicConfig()

  const hasStripeProcessor = () => {
    if (processorId) {
      let processor = processors?.find((p) => p.id === processorId)
      return processor ? processor.name === 'stripe' || processor.name === 'stripev2' : false
    }
    return stripeProcessor !== undefined

  }
  return (
    <Feature.Provider
      value={{
        hasNomination: features?.surveys?.nomination,
        hasPersonalDetails: features?.surveys?.personalDetails,
        hasContact: features?.surveys?.contact,
        hasGuestPass: features?.surveys?.guestPass,
        hasGuestDirectory: settings?.guestDirectory,
        hasRegistration: features?.surveys?.registration,
        hasStripeProcessor: hasStripeProcessor(),
        hasMemberDirectory: false,
        hasMessaging: false,
        hasSms: features?.sms,
        editPrimaryInfo: features?.editPrimaryInfo,
      }}
    >
      {children}
    </Feature.Provider>
  )
}

export { FeatureProvider, useFeatureToggles }

import { Col, List, Row, Typography } from 'antd'
import React, { FC, ReactNode } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useDLE } from 'rest-hooks'
import TransactionPaymentTable from 'src/components/transactions/TransactionPaymentTable'
import { Image, withPlaceholder } from 'src/sdk/components/image'
import { HorizontalSpace, VerticalSpace } from 'src/sdk/components/layout'
import { withListItem, withVerticalList } from 'src/sdk/components/list'
import { SectionLoader } from 'src/sdk/components/loader'
import { BreakpointMin } from 'src/sdk/components/screen/Breakpoint'
import Table from 'src/sdk/components/table/Table'
import { Tag } from 'src/sdk/components/tag'
import { withPrefix } from 'src/sdk/contexts/Config'
import { OrderEntity, OrderItem } from 'src/sdk/datasource/order'
import { StripHtmlTags } from 'src/sdk/helpers/strings'
import { Money } from '../../../../sdk/components/text'
import OrderItemDescription from './descriptions/OrderItemDescription'
import './OrderDetails.less'

export type ExpandedMobileProps = {
  title: string
  key: string
  render: (data: OrderItem) => {}
}

export type SummaryRowProps = {
  id: number | string
  title: string
  description: string | ReactNode
}

type MobileRowProps = {
  data: OrderItem
  columns: ExpandedMobileProps[]
}

const ItemPhoto: FC<Data.Source<OrderItem>> = ({ data }) => {
  if (data.photo) {
    return <Image className='payment-table-image' style={{ width: 200, height: 100 }} src={data.photo} />
  }
  let placeholder = withPlaceholder('shop-items')
  switch (data.type) {
    case 'event':
      placeholder = withPlaceholder('event-items')
      break
    case 'reservation':
    case 'appointment':
      placeholder = withPlaceholder('reservation-rooms')
      break
    case 'product':
      placeholder = withPlaceholder('shop-items')
      break
  }

  return <Image style={{ width: 200, height: 100 }} src={placeholder} />
}

const GenerateOrderTable = (order: OrderEntity) => {
  const columnsDesktop = [
    {
      title: '',
      dataIndex: 'photo',
      key: 'photo',
      render: (photo: string, data: OrderItem) => ({
        children: <ItemPhoto data={data} />,
        props: {
          width: '10%',
        },
      }),
    },
    {
      title: 'Description',
      key: 'description',
      render: (data: OrderItem) => ({
        children: (
          <VerticalSpace>
            <HorizontalSpace>
              <Typography.Text strong>{data.title}</Typography.Text>
              {data.status === 'cancelled' && (
                <Tag type={'error'} size={'small'}>
                  Cancelled
                </Tag>
              )}
            </HorizontalSpace>

            <OrderItemDescription data={data} />
          </VerticalSpace>
        ),
        props: {
          width: '35%',
        },
      }),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (data: number) => {
        return {
          children: data,
          props: {
            align: 'center',
          },
        }
      },
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (price: number, data: OrderItem) => {
        return {
          children: <Money strike={data.status === 'cancelled'}>{price}</Money>,
          props: {
            align: 'center',
          },
        }
      },
    },
    {
      title: 'Total',
      key: 'total',
      render: (data: OrderItem) => {
        return {
          children: <Money strike={data.status === 'cancelled'}>{data.price * data.quantity}</Money>,
          props: {
            align: 'right',
          },
        }
      },
    },
  ]

  const columnsMobile = [
    {
      title: 'Item',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (data: number) => {
        return {
          children: data,
        }
      },
    },
    {
      title: 'Total',
      key: 'total',
      render: (data: OrderItem) => {
        return {
          children: <Money>{data.subTotal}</Money>,
          props: {
            align: 'right',
          },
        }
      },
    },
  ]

  const columnsExpanded: ExpandedMobileProps[] = [
    {
      title: 'description',
      key: 'description',
      render: (data: OrderItem) => {
        return (
          <VerticalSpace>
            <HorizontalSpace>
              <Typography.Text strong>{data.title}</Typography.Text>
              {data.status === 'cancelled' && (
                <Tag type={'error'} size={'small'}>
                  Cancelled
                </Tag>
              )}
            </HorizontalSpace>
            <Typography.Text>{StripHtmlTags(data.description)}</Typography.Text>
          </VerticalSpace>
        )
      },
    },
    {
      title: 'quantity',
      key: 'quantity',
      render: (data: OrderItem) => {
        return data.quantity
      },
    },
    {
      title: 'total',
      key: 'total',
      render: (data: OrderItem) => {
        return <Money>{data.subTotal}</Money>
      },
    },
  ]
  return {
    columnsDesktop,
    columnsMobile,
    columnsExpanded,
    data: order,
  }
}

export const SummaryMobileRow: FC<Data.Source<SummaryRowProps>> = ({ data: { description, title } }) => (
  <Row>
    <Col span={12}>
      <Typography.Text strong>{title.charAt(0).toUpperCase() + title.slice(1)}</Typography.Text>
    </Col>
    <Col span={12}>
      <Typography.Text type={'secondary'}>{description}</Typography.Text>
    </Col>
  </Row>
)

export const StatementMobileList = withVerticalList<SummaryRowProps>((props) => (
  <List {...props} split={true} rowKey={(item) => `${item.id}`} renderItem={withListItem(SummaryMobileRow)} />
))

export const TableMobileRow: FC<MobileRowProps> = ({ data, columns }) => {
  const cols: SummaryRowProps[] = Object.entries(columns).map(([, value]) => ({
    id: value.key,
    title: value.title,
    description: value.render(data),
  }))

  return (
    <VerticalSpace size={40} style={{ marginBottom: 30 }}>
      <StatementMobileList dataSource={cols} split={true} />
    </VerticalSpace>
  )
}

type OrderDetailProps = {
  order: OrderEntity
  includeHeader?: boolean
  includePayments?: boolean
  includeFooter?: boolean
}

export const OrderDetails: FC<OrderDetailProps> = ({
  order,
  includeHeader = true,
  includePayments = true,
  includeFooter = true,
}) => {
  const isDesktop = useMediaQuery({ minWidth: BreakpointMin.LG })
  const {
    data: transactions,
    loading: loadingTransactions,
    error,
  } = useDLE(OrderEntity.transactions(), order && includePayments ? order.id : null)

  const { columnsDesktop, columnsMobile, columnsExpanded } = React.useMemo(() => GenerateOrderTable(order), [])

  return (
    <VerticalSpace size={16}>
      {includeHeader && (
        <Row align={'middle'} justify={'space-between'} gutter={[20, 40]}>
          <Col xs={{ order: 2 }} lg={{ order: 1 }}>
            <Typography.Title type={'secondary'} level={4}>
              Details
            </Typography.Title>
          </Col>
        </Row>
      )}
      <Row>
        <Table
          className={withPrefix('payment-table')}
          style={{ width: '100%' }}
          expandable={
            !isDesktop
              ? {
                  expandedRowRender: (props) => <TableMobileRow data={props} columns={columnsExpanded} />,
                }
              : {}
          }
          pagination={false}
          columns={isDesktop ? columnsDesktop : columnsMobile}
          rowKey={(item) => `${item.id}`}
          dataSource={order.items.filter((i) => i.price > 0)}
          summary={() => {
            return (
              includeFooter && (
                <>
                  {order.subTotal > 0 && (
                    <Table.Summary.Row className={withPrefix('payment-summary-row')}>
                      {isDesktop && <Table.Summary.Cell index={0} colSpan={2} />}
                      <Table.Summary.Cell
                        index={0}
                        align={'right'}
                        colSpan={isDesktop ? 2 : 3}
                        className={withPrefix('payment-summary-row-item')}
                      >
                        <Typography.Text strong>Subtotal</Typography.Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={0} align={'right'} className={withPrefix('payment-summary-row-item')}>
                        <Money>{order.subTotal}</Money>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  )}
                  {order.discountTotal > 0 && (
                    <Table.Summary.Row className={withPrefix('payment-summary-row')}>
                      {isDesktop && <Table.Summary.Cell index={0} colSpan={2} />}
                      <Table.Summary.Cell
                        index={0}
                        align={'right'}
                        colSpan={isDesktop ? 2 : 3}
                        className={withPrefix('payment-summary-row-item')}
                      >
                        <Typography.Text strong>Discount</Typography.Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={0} align={'right'} className={withPrefix('payment-summary-row-item')}>
                        <Money>{-order.discountTotal}</Money>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  )}
                  {order.tipTotal > 0 && (
                    <Table.Summary.Row className={withPrefix('payment-summary-row')}>
                      {isDesktop && <Table.Summary.Cell index={0} colSpan={2} />}
                      <Table.Summary.Cell
                        index={0}
                        align={'right'}
                        colSpan={isDesktop ? 2 : 3}
                        className={withPrefix('payment-summary-row-item')}
                      >
                        <Typography.Text strong>Tip</Typography.Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={0} align={'right'} className={withPrefix('payment-summary-row-item')}>
                        <Money>{order.tipTotal}</Money>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  )}
                  {order.shippingTotal > 0 && (
                    <Table.Summary.Row className={withPrefix('payment-summary-row')}>
                      {isDesktop && <Table.Summary.Cell index={0} colSpan={2} />}
                      <Table.Summary.Cell
                        index={0}
                        align={'right'}
                        colSpan={isDesktop ? 2 : 3}
                        className={withPrefix('payment-summary-row-item')}
                      >
                        <Typography.Text strong>Shipping</Typography.Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={0} align={'right'} className={withPrefix('payment-summary-row-item')}>
                        <Money>{order.shippingTotal}</Money>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  )}
                  {order.serviceFeeTotal > 0 && (
                    <Table.Summary.Row className={withPrefix('payment-summary-row')}>
                      {isDesktop && <Table.Summary.Cell index={0} colSpan={2} />}
                      <Table.Summary.Cell
                        index={0}
                        align={'right'}
                        colSpan={isDesktop ? 2 : 3}
                        className={withPrefix('payment-summary-row-item')}
                      >
                        <Typography.Text strong>Service Fee</Typography.Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={0} align={'right'} className={withPrefix('payment-summary-row-item')}>
                        <Money>{order.serviceFeeTotal}</Money>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  )}
                  <Table.Summary.Row className={withPrefix('payment-summary-row')}>
                    {isDesktop && <Table.Summary.Cell index={0} colSpan={2} />}
                    <Table.Summary.Cell
                      index={0}
                      align={'right'}
                      colSpan={isDesktop ? 2 : 3}
                      className={withPrefix('payment-summary-row-item')}
                    >
                      <Typography.Text strong>Tax</Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={0} align={'right'} className={withPrefix('payment-summary-row-item')}>
                      <Money>{order.taxTotal}</Money>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                  <Table.Summary.Row className={withPrefix('payment-summary-row')}>
                    {isDesktop && <Table.Summary.Cell index={0} colSpan={2} />}
                    <Table.Summary.Cell
                      index={0}
                      align={'right'}
                      colSpan={isDesktop ? 2 : 3}
                      className={withPrefix('payment-summary-row-item')}
                    >
                      <Typography.Text strong>Total</Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={0} align={'right'} className={withPrefix('payment-summary-row-item')}>
                      <Money>{order.total}</Money>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              )
            )
          }}
        />
      </Row>
      <Row>
        {includePayments && (
          <Col span={24}>
            <Typography.Title type={'secondary'} level={4}>
              Transactions
            </Typography.Title>
            {loadingTransactions ? <SectionLoader /> : <TransactionPaymentTable data={transactions} />}
          </Col>
        )}
      </Row>
    </VerticalSpace>
  )
}

export const SummaryRow: FC<Data.Source<SummaryRowProps>> = ({ data }) => (
  <Row align={'top'} justify={'space-between'} wrap={false}>
    <Col flex={'auto'}>
      <Typography.Text type={'secondary'}>{data.title}</Typography.Text>
    </Col>
    <Col flex={'auto'} style={{ textAlign: 'right' }}>
      <Typography.Text strong>{data.description}</Typography.Text>
    </Col>
  </Row>
)

export const StatementSummaryList = withVerticalList<SummaryRowProps>((props) => (
  <List
    {...props}
    split={true}
    header={
      props.header ? (
        <Typography.Title type={'secondary'} level={5}>
          {props.header}
        </Typography.Title>
      ) : undefined
    }
    className={withPrefix('statement-summary')}
    renderItem={withListItem(SummaryRow)}
  />
))

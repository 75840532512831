import { notification, Typography } from 'antd'
import moment from 'moment'
import { FC, useState } from 'react'
import { FormattedTime } from 'react-intl'
import { useController, useDLE } from 'rest-hooks'
import ExpandableList from 'src/sdk/components/collapse/ExpandableList'
import LocalFormattedDate from 'src/sdk/components/datetime/LocalFormattedDate'
import { DrawerDetail } from 'src/sdk/components/drawer/DrawerDetail'
import { Button } from 'src/sdk/components/form'
import IvyIcon from 'src/sdk/components/icon'
import { HorizontalSpace, VerticalSpace } from 'src/sdk/components/layout'
import { Section } from 'src/sdk/components/layout/Section'
import GoogleMap from 'src/sdk/components/map/GoogleMap'
import Modal from 'src/sdk/components/modal/Modal'
import SurveyForm from 'src/sdk/components/survey/SurveyForm'
import { Tag } from 'src/sdk/components/tag'
import { Address } from 'src/sdk/components/text/Address'
import { Markup } from 'src/sdk/components/text/Markup'
import { WebsiteLink } from 'src/sdk/components/text/WebsiteLink'
import { useNotification } from 'src/sdk/contexts/Notification'
import { AccountAppointment, AccountReservation } from 'src/sdk/datasource/account/account'
import { ScheduleType } from 'src/sdk/datasource/scheduler'
import { FormFieldValue, SurveyEndpoint, SurveyEntity } from 'src/sdk/datasource/survey'
import { toTimezoneAbbrev } from 'src/sdk/datasource/timezones'
import { Capitalize, Pluralize } from 'src/sdk/helpers/strings'
import BookingCancelButton from './BookingCancelButton'

type BookingFormProps = {
  surveyEndpoint: SurveyEndpoint
  bookingId: Data.ID
  useRequired?: boolean
}

const BookingForm: FC<BookingFormProps> = ({ surveyEndpoint, bookingId, useRequired }) => {
  const { fetch } = useController()
  const { notifyOnError } = useNotification()
  const [visible, setVisible] = useState(useRequired)
  const [submitting, setSubmitting] = useState(false)
  const { data, loading } = useDLE(SurveyEntity.get(), { id: surveyEndpoint.uid })

  const onFinish = async (values: FormFieldValue[]) => {
    setSubmitting(true)
    await fetch(SurveyEntity.endpointSend(), surveyEndpoint.uid, values)
      .then((response) => {
        setVisible(false)
        const message = data?.confirmationHeader ? data?.confirmationHeader : 'Success'
        const description = data?.confirmationText ? data?.confirmationText : 'Thank you for your responses'
        notification.success({ message: message, description: description })
      })
      .catch(notifyOnError)
      .finally(() => setSubmitting(false))
  }

  return (
    <>
      <Button
        loading={loading}
        onClickCapture={() => setVisible(true)}
        block
        type={'ghost'}
        icon={<IvyIcon type={'action/form'} />}
      >
        {data && data.name}
      </Button>
      {data && (
        <Modal
          zIndex={1001}
          maskClosable={useRequired && !data.hasRequiredFields}
          closable={useRequired && !data.hasRequiredFields}
          destroyOnClose
          visible={visible}
          footer={null}
          onCancel={() => setVisible(false)}
        >
          <Section title={data.name} subTitle={<Markup sanitized={false} html={data.description} />}>
            <SurveyForm
              staticBtn={false}
              form={data}
              onFinish={(values) => onFinish(values)}
              loading={submitting}
              showTitle={false}
              showDescription={false}
              btnProps={{ block: true }}
              name={`booking-survey-${bookingId}`}
            />
          </Section>
        </Modal>
      )}
    </>
  )
}

type MyBookingDetailsProps = {
  type: ScheduleType
  id: Data.ID
  useRequired?: boolean
}

const MyBookingDetails: FC<MyBookingDetailsProps> = ({ type, ...props }) => {
  return type === 'appointment' ? <AppointmentBooking {...props} /> : <ReservationBooking {...props} />
}

const ReservationBooking: FC<Omit<MyBookingDetailsProps, 'type'>> = ({ id, useRequired }) => {
  const { data, loading } = useDLE(AccountReservation.detail(), { id: id })
  return <BookingDetailsView data={data} loading={loading} useRequired={useRequired} />
}

const AppointmentBooking: FC<Omit<MyBookingDetailsProps, 'type'>> = ({ id, useRequired }) => {
  const { data, loading } = useDLE(AccountAppointment.detail(), { id: id })

  return <BookingDetailsView data={data} loading={loading} useRequired={useRequired} />
}

interface IBookingDetailsView {
  data?: AccountReservation | AccountAppointment
  loading: boolean
  useRequired?: boolean
}

const BookingDetailsView: FC<IBookingDetailsView> = ({ data, loading, useRequired }) => {
  return (
    <DrawerDetail<AccountReservation | AccountAppointment>
      loading={loading}
      data={data}
      header={{
        title: (data) => `${data.title} ${data.type}`,
        image: (data) => {
          return {
            src: data.photo,
          }
        },
      }}
      main={(data) => (
        <VerticalSpace>
          <Section title={'About'}>
            <Typography.Text type={'secondary'}>{<Markup html={data.description} sanitized={false} />}</Typography.Text>
          </Section>
          {data.location && (
            <Section title={'Location'} titleSize={5}>
              <VerticalSpace size={10}>
                <Typography.Text type={'secondary'}>{data.location.name}</Typography.Text>
                {data.location.address && <Address type={'secondary'} data={data.location.address} />}
                {data.location.website && <WebsiteLink link={data.location.website} />}
                {data.location.hours && data.locationHours && data.locationHours.length > 0 && (
                  <ExpandableList
                    title={
                      data.location.hours.today === 'Closed' ? 'Closed Today' : `Today ${data.location.hours.today}`
                    }
                    type={'secondary'}
                    data={data.locationHours}
                  />
                )}
              </VerticalSpace>
            </Section>
          )}

          {data.location && data.location.latitude && data.location.longitude && (
            <Section style={{ width: '100%', height: 300 }}>
              <GoogleMap
                content={{
                  title: data.location.name,
                  address: `${data.location.address?.address} ${
                    data.location.address?.address2 && ` | ${data.location.address?.address2}`
                  }`,
                  city: data.location.address?.city,
                  state: data.location.address?.state,
                  zipCode: data.location.address?.zipCode,
                }}
                location={{ lat: data.location.latitude, lng: data.location.longitude }}
                height={300}
                delay={500}
              />
            </Section>
          )}
        </VerticalSpace>
      )}
      card={{
        title: 'Details',
        list: (data) => [
          {
            key: 'booking-date',
            title: (
              <Typography.Text strong>
                {moment(data.startDateLocal.date).isSame(moment(), 'day')
                  ? 'Today'
                  : moment(data.startDateLocal.date).format('dddd MMMM Do, YYYY')}
              </Typography.Text>
            ),
            description: 'Date',
            icon: 'application/calendar',
          },
          {
            key: 'booking-time',
            title:
              data.schedule.blockType === 'multi' ? (
                <HorizontalSpace size={4}>
                  <HorizontalSpace split={'-'}>
                    <Typography.Text strong>
                      <LocalFormattedDate value={data.startDateLocal} />
                    </Typography.Text>
                    <Typography.Text strong>
                      <FormattedTime value={data.endDateLocal.date} timeStyle={'short'} />
                    </Typography.Text>
                  </HorizontalSpace>
                  <Typography.Text strong>{toTimezoneAbbrev(data.timezoneId)}</Typography.Text>
                </HorizontalSpace>
              ) : (
                <Typography.Text strong>
                  {moment(data.startDateLocal.date).format('h:mm A')} for{' '}
                  {moment(data.endDateLocal.date).diff(moment(data.startDateLocal.date), 'minutes')} minutes
                </Typography.Text>
              ),
            description: 'Time',
            icon: 'symbol/clock-circle',
          },
          {
            ...(data.guest
              ? {
                  key: 'booking-guest',
                  title: <Typography.Text>{data.guest.fullName}</Typography.Text>,
                  description: 'Guest',
                  icon: 'custom/user',
                }
              : {}),
          },
          {
            ...(data.guest && data.guest.id !== data.owner?.id
              ? {
                  key: 'booking-owner',
                  title: <Typography.Text>{data.owner?.fullName}</Typography.Text>,
                  description: 'Booked By',
                  icon: 'custom/user',
                }
              : {}),
          },
          data.type === 'reservation'
            ? {
                key: 'booking-party-size',
                title: (
                  <Typography.Text strong>
                    {Capitalize(data.type)} for {data.partySize} {Pluralize(data.partySize, ['person', 'people'])}
                  </Typography.Text>
                ),
                description: 'Party Size',
                icon: 'custom/people',
              }
            : {},
          data.location?.address
            ? {
                key: 'booking-address',
                title: data.location?.address ? (
                  <Address strong data={data.location.address} />
                ) : (
                  <Typography.Text style={{ marginBottom: 0 }} strong>
                    {data.location?.name}
                  </Typography.Text>
                ),
                description: 'Location',
                icon: 'custom/location',
              }
            : {},
          data.status === 'cancelled'
            ? {
                key: 'booking-status',
                title: (
                  <Tag type={'error'} size={'small'}>
                    Cancelled
                  </Tag>
                ),
                description: '',
              }
            : {},
        ],
        headerActions: {
          calendar: (data) => {
            return {
              title: data.title,
              description: `Your ${data.title} ${data.type} is booked`,
              location: data?.location?.address?.fullAddress,
              startDate: data.startDateLocal,
              endDate: data.endDateLocal,
            }
          },
        },
        footerButtons: (data) => [
          data.survey && data.canCancel && (
            <BookingForm
              key={'booking-form'}
              useRequired={useRequired}
              surveyEndpoint={data.survey}
              bookingId={data.id}
            />
          ),
          data.canCancel && <BookingCancelButton key={'booking-cancel'} type={data.type} bookingId={data.id} />,
        ],
      }}
    />
  )
}

export default MyBookingDetails

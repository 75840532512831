import { Col, Input, Row } from 'antd'
import { createContext, Dispatch, FC, PropsWithChildren, ReactNode, SetStateAction, useContext, useMemo } from 'react'
import { Item } from 'src/sdk/components/form'
import { FormRuleProps } from 'src/sdk/components/form/Form'
import { usePrivateConfig, usePublicConfig, withPrefix } from 'src/sdk/contexts/Config'
import { PaymentFormValue } from 'src/sdk/datasource/checkout'
import { PaymentMethod } from 'src/sdk/datasource/payment'
import { CreditCardCreateForm, CreditCardEntity } from 'src/sdk/datasource/wallet/creditcard'
import { StripeProvider } from './Stripe'

export type CardInformationProps = {
  isMobile?: boolean
  rules?: FormRuleProps<Partial<PaymentFormValue>>
  disabled?: boolean
}

export type HandlePaymentProps = {
  amount: number
  paymentMethods?: PaymentMethod[]
  newMethod?: PaymentFormValue
}

export type NewPaymentMethod = {
  paymentMethod: PaymentMethod
  cardDetails?: CreditCardEntity
}

export interface PaymentInterface {
  CardInformation: FC<CardInformationProps>
  validateCard?: () => boolean
  isValid: boolean
  loading: boolean
  paymentDetails: PaymentFormValue | undefined
  setPaymentDetails: Dispatch<SetStateAction<PaymentFormValue | undefined>>
  createPaymentMethod: (values: CreditCardCreateForm, amount?: number) => Promise<NewPaymentMethod | undefined>
}
const defaultRules: FormRuleProps<Partial<PaymentFormValue>> = {
  cardNumber: [
    {
      required: true,
      message: '',
    },
  ],
  cardExpiry: [
    {
      required: true,
      message: '',
    },
  ],
  cardCvc: [
    {
      required: true,
      message: '',
    },
  ],
}

const CardInformation: FC<CardInformationProps> = ({ isMobile = false, rules = defaultRules }) => {
  return (
    <Row className={withPrefix('payment-form-items')}>
      <Col span={24} className={!isMobile ? withPrefix('payment-form-items-full') : ''}>
        <Item rules={rules?.cardNumber} name={'cardNumber'}>
          <Input size={'middle'} type={'text'} placeholder={'1234 1234 1234 1234'} />
        </Item>
      </Col>
      <Col span={24} sm={12} className={!isMobile ? withPrefix('payment-form-items-half') : ''}>
        <Item rules={rules?.cardExpiry} name={'cardExpiry'}>
          <Input size={'middle'} type={'text'} placeholder={'MM/YY'} />
        </Item>
      </Col>
      <Col span={24} sm={12} className={!isMobile ? withPrefix('payment-form-items-half') : ''}>
        <Item rules={rules?.cardCvc} name={'cardCvc'}>
          <Input size={'middle'} type={'text'} placeholder={'CVC'} />
        </Item>
      </Col>
    </Row>
  )
}

const PaymentProviderByName: FC = ({ children }) => {
  const { defaultProcessor, stripeProcessor } = usePublicConfig()
  return useMemo(() => {
    var processor =
      defaultProcessor?.name === 'stripe' || defaultProcessor?.name === 'stripev2' ? defaultProcessor : stripeProcessor
    switch (processor?.name) {
      case 'stripe':
      case 'stripev2':
        return <StripeProvider id={processor.id}>{children}</StripeProvider>
      default:
        return <>{children}</>
    }
  }, [])
}

const PaymentContext = createContext<PaymentInterface>({
  CardInformation: CardInformation,
  validateCard: () => false,
  isValid: false,
  loading: false,
  paymentDetails: undefined,
  setPaymentDetails: (value) => value,
  createPaymentMethod: (values) => new Promise(() => {}),
})

function PaymentProvider({ children }: PropsWithChildren<ReactNode>) {
  return <PaymentProviderByName>{children}</PaymentProviderByName>
}

const usePayment: () => PaymentInterface = () => {
  return useContext(PaymentContext)
}

export { PaymentProvider, usePayment, PaymentContext }

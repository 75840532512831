import { Form, FormInstance, Input, InputNumber, Radio, TimePicker as Wrapped, TimePickerProps } from 'antd'
import { NamePath } from 'antd/lib/form/interface'
import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import { Button, Item } from 'src/sdk/components/form'
import IvyIcon from 'src/sdk/components/icon'
import { withPrefix } from 'src/sdk/contexts/Config'
import './TimePicker.less'

type TimeDirection = 'up' | 'down'

type TimePickerArrowProps = {
  disabled: boolean
  direction: TimeDirection
  onClick: (direction: TimeDirection) => void
}
const TimePickerArrow: FC<TimePickerArrowProps> = ({ disabled, direction, onClick }) => (
  <Button
    disabled={disabled}
    className={withPrefix('time-picker-arrow-btn', `time-picker-arrow-btn-${direction}`)}
    type={'link'}
    htmlType={'button'}
    onClick={() => onClick(direction)}
  >
    <IvyIcon size={24} type={'custom/chevron'} />
  </Button>
)

type TimePickProps = {
  name?: string | string[]
  use12Hours?: boolean
  format?: string
  hourStep?: number
  minuteStep?: number
  prefixZero?: boolean
  disabled?: boolean
  startTime?: moment.Moment
  value?: moment.Moment
  form?: FormInstance
  onChange?: (date: moment.Moment, dateString: string) => void
}

export const TimePick: FC<TimePickProps> = ({
  form,
  name,
  use12Hours = true,
  format = 'YYYY-MM-DD h:mm A',
  value,
  startTime,
  hourStep = 1,
  minuteStep = 15,
  prefixZero = true,
  disabled = false,
  onChange,
}) => {
  const hoursFormat = use12Hours ? (prefixZero ? 'hh' : 'h') : 'HH'
  const current = value ? value : startTime ? startTime : moment()

  const [hours, setHours] = useState<string>(current.format(hoursFormat))
  const [minutes, setMinutes] = useState<string>(`${(Math.round(Number(current.format('mm')) / 15) * 15) % 60}`)
  const [period, setPeriod] = useState<string>(current.format('A'))

  const [buttonState, setButtonState] = useState({
    hoursUp: false,
    hoursDown: false,
    minutesUp: false,
    minutesDown: false,
  })

  const hoursMax = use12Hours ? 12 : 24
  const hoursMin = use12Hours ? 1 : 0
  const minuteMax = 60 - minuteStep

  const timeUpdated = (value: moment.Moment) => {
    // If this component is within a form, update the field value
    if (form && name) {
      form.setFieldsValue({
        [Array.isArray(name) ? name.join('_') : name]: value,
      })
    }
    onChange && onChange(value, value.format(format))
  }

  const handlePeriodChange = (value: string) => {
    setPeriod(value)
    const time = moment(`${hours}:${minutes}${value}`, `${hoursFormat}:mmA`)
    timeUpdated(time)
  }

  const handleHourChange = (direction: TimeDirection) => {
    let value = ''
    switch (direction) {
      case 'up':
        value = `${Number(hours) + hourStep}`
        if (use12Hours && Number(value) > 12) {
          value = '1'
        } else if (Number(value) > 23) {
          value = '0'
        }
        break
      case 'down':
        value = `${Number(hours) - hourStep}`
        if (use12Hours && value === '0') {
          value = use12Hours ? '12' : ''
        } else if (Number(value) < 0) {
          value = '23'
        }
        break
    }

    setHours(`${prefixZero && value.length === 1 ? 0 : ''}${value}`)
    const time = moment(`${value}:${minutes}${period}`, `${hoursFormat}:mmA`)
    timeUpdated(time)
  }

  const handleMinuteChange = (direction: TimeDirection) => {
    let value = ''
    let numberValue = Number(minutes)
    switch (direction) {
      case 'up':
        value = `${Number(minutes) + minuteStep < 60 ? Number(minutes) + minuteStep : 0}`
        break
      case 'down':
        value = `${Number(minutes) - minuteStep}`
        break
    }
    if (Number(value) < 0) {
      value = '45'
    } else if (Number(value) > 45) {
      value = '0'
    }
    setMinutes(`${value}`)
    const time = moment(`${hours}:${value}${period}`, `${hoursFormat}:mmA`)
    timeUpdated(time)
  }

  const setTime = (time: moment.Moment) => {
    setHours(time.format(hoursFormat))
    setMinutes(`${(Math.round(Number(time.format('mm')) / 15) * 15) % 60}`)
    setPeriod(time.format('A'))

    if (form && name) {
      form.setFieldsValue({
        [Array.isArray(name) ? name.join('_') : name]: value,
      })
    }
    onChange && onChange(time, time.format(format))
  }

  useEffect(() => {
    setButtonState((prevState) => ({
      ...prevState,
      hoursUp: false,
      hoursDown: false,
      minutesUp: false,
      minutesDown: false,
      // minutesUp: Number(minutes) === minuteMax,
      // minutesDown: Number(minutes) === 0,
    }))
  }, [hours, minutes, period])

  useEffect(() => {
    if (value === current) return
    value && setTime(value)
  }, [value])

  return (
    <div className={withPrefix(`time-picker`, `${use12Hours ? 'time-picker-12' : ''}`)}>
      <div className={withPrefix('time-picker-item')}>
        <TimePickerArrow disabled={buttonState.hoursUp || disabled} direction={'up'} onClick={handleHourChange} />
        <Input
          disabled={disabled}
          readOnly
          min={use12Hours ? 1 : 0}
          max={use12Hours ? 12 : 24}
          type={'number'}
          value={hours}
          className={withPrefix('time-picker-hours')}
        />
        <TimePickerArrow disabled={buttonState.hoursDown || disabled} direction={'down'} onClick={handleHourChange} />
      </div>
      <div className={withPrefix('time-picker-colon')} />
      <div className={withPrefix('time-picker-item')}>
        <TimePickerArrow disabled={buttonState.minutesUp || disabled} direction={'up'} onClick={handleMinuteChange} />
        <Input
          disabled={disabled}
          readOnly
          min={0}
          max={60}
          value={`${minutes.length === 1 ? '0' : ''}${minutes}`}
          className={withPrefix('time-picker-minutes')}
        />
        <TimePickerArrow
          disabled={buttonState.minutesDown || disabled}
          direction={'down'}
          onClick={handleMinuteChange}
        />
      </div>
      {use12Hours && (
        <div>
          <Radio.Group
            value={period}
            className={withPrefix('time-picker-period-wrapper')}
            onChange={(evt) => handlePeriodChange(evt.target.value)}
            disabled={disabled}
          >
            <Radio.Button className={withPrefix('time-picker-period')} type={'radio'} value={'AM'}>
              AM
            </Radio.Button>
            <Radio.Button className={withPrefix('time-picker-period')} type={'radio'} value={'PM'}>
              PM
            </Radio.Button>
          </Radio.Group>
        </div>
      )}
    </div>
  )
}

export const TimePicker: FC<TimePickerProps> = (props) => {
  const now = moment()
  return (
    <Wrapped
      use12Hours
      format={props.format ?? 'hh:mm A'}
      defaultValue={moment(now).add(60 - (now.minute() % 60), 'minutes')}
      minuteStep={15}
      showSecond={false}
      placeholder={'Time'}
      suffixIcon={<IvyIcon size={16} type={'custom/appointments'} />}
      size={'middle'}
      {...props}
    />
  )
}

import { Resource } from '@rest-hooks/rest'
import { useResource } from 'rest-hooks'

import { ApiResource } from './entity'

export type Group =
  | ''
  | 'events'
  | 'restaurants'
  | 'my_club'
  | 'dining'
  | 'meeting_rooms'
  | 'hotel_rooms'
  | 'club_benefits'
  | 'club_directory'
  | 'appointments'
  | 'spa_wellness'
  | 'spa'

export type CategoryType =
  | 'general'
  | 'crm'
  | 'contest'
  | 'coupon'
  | 'survey'
  | 'review'
  | 'event'
  | 'ticket'
  | 'billing'
  | 'content'
  | 'locator'
  | 'newsletter'
  | 'text_message'
  | 'social_post'
  | 'campaign'
  | 'loyalty'
  | 'membership'
  | 'appointment'
  | 'directory_location'
  | 'service'
  | 'product'
  | 'order'
  | 'appointment_slot'
  | 'transaction'
  | 'reward_activity'
  | 'keyword'
  | 'credit'
  | 'webhook'
  | 'loyalty_redemption'
  | 'membership_card'
  | 'note'
  | 'survey_group'
  | 'funding_campaign'
  | 'membership_card_activity'
  | 'gallery'

export type CategoryQuery = {
  type: CategoryType
}

class CategoryEntity extends ApiResource implements Data.Identified, Data.Category {
  static urlRoot = `/api/public/categories`
  readonly id: Data.ID = 0
  readonly name: string = ''
  readonly group: string = ''
  readonly order: number = 0
  readonly featured: boolean = false
  readonly description: string = ''
  readonly graphicThumb: string = ''
  readonly categoryAbove: number = 0
  readonly parentCategory?: CategoryEntity
  readonly childCategories?: CategoryEntity[]

  static schema = {
    childCategories: [CategoryEntity],
  }
  get image(): string {
    return this.graphicThumb
  }

  get images(): string[] {
    return [this.image]
  }

  get cleanName(): string {
    return this.name.replaceAll(' ', '-').toLowerCase()
  }

  get mainGroup(): string {
    return this.parentCategory ? this.parentCategory.group : this.group
  }

  static extract<T extends Data.Categorized<CategoryEntity>>(data: T[]): CategoryEntity[] {
    return data.flatMap((it) => it.categories).filter(super.distinct)
  }

  static getAll(params: CategoryQuery): CategoryEntity[] {
    return useResource(
      this.list().extend({
        url: ({ type, ...params }) =>
          `${this.urlRoot}/components/${type}${params.length > 0 ? `?${this.queryString(params)}` : ''}`,
      }),
      params,
    )
  }

  static group<T extends typeof Resource>(this: T) {
    const endpoint = this.list()

    return endpoint.extend({
      fetch: (group: Group) => {
        return endpoint.fetch.call(
          endpoint.extend({
            url: () => `${this.urlRoot}/groups/${group}`,
          }),
          {},
        )
      },
    })
  }
  static getByGroup(group: Group): CategoryEntity[] {
    return useResource(
      this.list().extend({
        url: () => `${this.urlRoot}/groups/${group}`,
      }),
      {},
    )
  }
}

export { CategoryEntity }

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Col, Row } from 'antd'
import { createContext, FC, PropsWithChildren, ReactElement, ReactNode, useContext, useMemo, useState } from 'react'
import { ApiError } from 'src/sdk/api'
import { Item } from 'src/sdk/components/form'
import { CompactGroup, CompactItem } from 'src/sdk/components/form/CompactGroup'
import { FormRuleProps } from 'src/sdk/components/form/Form'
import { usePrivateConfig, usePublicConfig, withPrefix } from 'src/sdk/contexts/Config'
import { PaymentFormValue } from 'src/sdk/datasource/checkout'
import { PaymentMethod } from 'src/sdk/datasource/payment'
import { CreditCardCreateForm } from 'src/sdk/datasource/wallet/creditcard'
import { CardInformationProps, NewPaymentMethod, PaymentContext } from './Payment'

const defaultRules: FormRuleProps<Partial<PaymentFormValue>> = {
  cardNumber: [
    {
      required: true,
      message: '',
    },
  ],
  cardExpiry: [
    {
      required: true,
      message: '',
    },
  ],
  cardCvc: [
    {
      required: true,
      message: '',
    },
  ],
}

const CardInformation = ({ isMobile, rules = defaultRules, disabled }: CardInformationProps) => (
  <Row className={withPrefix('payment-form-items')} key={'payment-card-form'}>
    <Col span={24}>
      <Item key={'cardNumber'} rules={rules?.cardNumber} name={'cardNumber'} label={'Card Number'}>
        <CardNumberElement key={'stripe_card_number'} options={{ showIcon: true }} className={withPrefix('input')} />
      </Item>
    </Col>
    <Col span={24}>
      <CompactGroup>
        <CompactItem width={50} rules={rules?.cardExpiry} name={'cardExpiry'} label={'Expiration'}>
          <CardExpiryElement key={'stripe_card_expiry'} className={withPrefix('input')} />
        </CompactItem>
        <CompactItem width={50} rules={rules?.cardCvc} name={'cardCvc'} label={'CVC'}>
          <CardCvcElement key={'stripe_card_cvc'} className={withPrefix('input')} />
        </CompactItem>
      </CompactGroup>
    </Col>
  </Row>
)

function StripePaymentProvider({ id, children }: PropsWithChildren<ReactNode> & { id: Data.ID }) {
  const elements = useElements()
  const stripe = useStripe()
  const [paymentDetails, setPaymentDetails] = useState<PaymentFormValue | undefined>()
  const [isValid, setIsValid] = useState(false)
  const [loading, setLoading] = useState(false)

  const createPaymentMethod = async (cardDetails: CreditCardCreateForm, amount?: number) => {
    const cardNumber = elements?.getElement(CardNumberElement)
    if (!stripe || !cardNumber) return undefined
    setLoading(true)

    return stripe
      .createPaymentMethod({
        type: 'card',
        card: cardNumber,
        billing_details: {
          name: cardDetails.name,
          address: {
            country: cardDetails.country,
            postal_code: cardDetails.zipCode,
          },
        },
      })
      .then((response) => {
        if (response.error || !response.paymentMethod) {
          throw new ApiError(400, {
            title: 'Credit Card Error',
            errorDescription: response.error?.message ?? 'There was a problem adding this card',
          })
        } else {
          return {
            paymentMethod: new PaymentMethod({
              id: response.paymentMethod.id,
              amount: amount,
              type: 'newcreditcard',
              save: cardDetails.saveCard,
              processorId: id,
              billingDetails: {
                name: cardDetails.name,
                country: cardDetails.country,
                zipCode: cardDetails.zipCode,
              },
            }),
            cardDetails: {
              id: response.paymentMethod.id,
              nickname: cardDetails.nickname,
              billingUse: cardDetails.billingUse,
              billingDetails: {
                name: cardDetails.name,
                country: cardDetails.country,
                zipCode: cardDetails.zipCode,
              },
            },
          } as NewPaymentMethod
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <PaymentContext.Provider
      value={{
        CardInformation: CardInformation,
        validateCard: () => isValid,
        isValid,
        loading,
        paymentDetails,
        setPaymentDetails,
        createPaymentMethod,
      }}
    >
      {children}
    </PaymentContext.Provider>
  )
}

type StripeProviderContext = {
  processorId?: Data.ID
  publishableKey?: string
}

interface IStripeProvider {
  id: Data.ID
}

const StripePayment = createContext<StripeProviderContext>({
  processorId: 0,
  publishableKey: undefined,
})

function StripeProvider({ id, children }: PropsWithChildren<IStripeProvider>) {
  const { processors } = usePublicConfig()
  const [processorId, setProcessorId] = useState<Data.ID>()
  const [publishableKey, setPublishableKey] = useState<string>()
  const [loaded, setLoaded] = useState(false)
  useMemo(() => {
    const processor = processors.find((p) => p.id === id)
    if (processor && !loaded) {
      setLoaded(true)
      setProcessorId(processor.id)
      setPublishableKey(processor.key)
    }
  }, [id])

  return (
    <StripePayment.Provider
      value={{
        publishableKey,
        processorId,
      }}
    >
      {publishableKey && (
        <Elements stripe={loadStripe(publishableKey)}>
          <StripePaymentProvider id={id}>{children}</StripePaymentProvider>
        </Elements>
      )}
    </StripePayment.Provider>
  )
}

const useStripeProvider: () => StripeProviderContext = () => useContext(StripePayment)

export { StripeProvider, useStripeProvider }

import { useResource } from 'rest-hooks'
import { DateTz } from '../api'
import { getLocalTime } from '../helpers/dateFormatter'
import { Entity, Resource } from '@rest-hooks/rest'
import { AccountEvent, AccountTicket } from './account/account'
import { AbstractCartItem } from './cart'
import { CategoryEntity } from './category'
import { ApiResource, SchemaPaginated } from './entity'
import { DateRangeEntity } from './scheduler'
import { SurveyEndpoint } from './survey'

class Ticket extends Entity implements Data.Named, Data.Described {
  readonly id: Data.ID = ''
  readonly title: string = ''
  readonly eventId: number = 0
  readonly name: string = ''
  readonly description: string = ''
  readonly price: number = 0.0
  readonly onSale: Date | undefined
  readonly offSale: Date | undefined
  readonly availableQuantity: number = 0
  readonly maximum: number = 0
  readonly minimum: number = 0
  readonly requiresPasscode: boolean = false
  readonly purchased: AccountTicket[] = []
  readonly startDate: Date = new Date()
  readonly endDate: Date = new Date()
  readonly startDateLocal: DateTz = DateTz.fromJS({})
  readonly endDateLocal: DateTz = DateTz.fromJS({})

  pk(): string {
    return `${this.id}`
  }
}

class EventCartItem extends AbstractCartItem {
  readonly ticket: Ticket = Ticket.fromJS()

  pk(): string | undefined {
    return `${this.ticket.id}`
  }

  static get key() {
    return '/api/cart/items/tickets'
  }

  get item(): Ticket {
    return this.ticket
  }

  get itemId(): Data.ID {
    return this.ticket.id
  }
}

export type EventType = 'ticket' | 'register' | 'public' | 'external'

export type EventQuery = {
  categoryId?: number[] | Data.ID[] | Data.ID | string
  venue?: string
  startDate?: Date | string
  endDate?: Date | string
  search?: string
  group?: string
} & Data.Paginated

type EventPhoto = {
  title: string
  description: string
  url: string
  main: boolean
}

export type EventSettings = {
  transferEnabled: boolean
  cancelEnabled: boolean
  socialEnabled: boolean
  printEnabled: boolean
  calendarEnabled: boolean
  buttonText: string
  callToAction: string
  verb: string
}

class EventResource extends ApiResource implements Data.Imaginated, Data.Searchable, Data.Temporal {
  static urlRoot = `/api/events`
  readonly id: Data.ID = 0
  readonly title: string = ''
  readonly description: string = ''
  readonly summary: string = ''
  readonly venue: string = ''
  readonly venueAddress: string = ''
  readonly graphic: string = ''
  readonly media: EventPhoto[] = []
  readonly externalLink: string = ''
  readonly type: EventType = 'ticket'
  readonly categoryId: number = 0
  readonly timezone: number = 0
  readonly timezoneId: string = ''
  readonly recurring: boolean = false
  readonly uid: string = ''
  readonly categories: CategoryEntity[] = []
  readonly tickets: Ticket[] = []
  readonly startDate: Date = new Date()
  readonly hideTickets?: boolean
  readonly endDate: Date = new Date()
  readonly startDateLocal: DateTz = DateTz.fromJS({})
  readonly endDateLocal: DateTz = DateTz.fromJS({})
  readonly availableQuantity: number = 0
  readonly registered: boolean = false
  readonly settings: EventSettings = {
    transferEnabled: false,
    cancelEnabled: false,
    socialEnabled: false,
    printEnabled: false,
    calendarEnabled: false,
    buttonText: '',
    callToAction: '',
    verb: '',
  }
  readonly reserveEnabled: boolean = false
  readonly waitlistFormId: number = 0
  readonly waitlist?: SurveyEndpoint
  readonly endpoint: string = ''
  readonly allowTransfer: boolean = false
  readonly allowCancel: boolean = false

  get name(): string {
    return this.title
  }

  get image(): string {
    return this.graphic
  }

  get images(): string[] {
    return this.media.map((value) => value.url)
  }

  get soldOut(): boolean {
    if (this.type === 'public' || this.type === 'external') return false
    return this.tickets.length > 0 ? this.tickets.every((t) => t.availableQuantity === 0) : true
  }

  get hasFreeTickets(): boolean {
    return this.tickets.some((ticket) => ticket.price === 0)
  }

  get hasPaidTickets(): boolean {
    return this.tickets.some((ticket) => ticket.price > 0)
  }

  get ticketsMinPrice(): number {
    return this.tickets
      .map((value) => value.price)
      .reduce(
        (previousValue, currentValue) =>
          previousValue > currentValue || previousValue === 0 ? currentValue : previousValue,
        0,
      )
  }
  a
  get price(): number {
    return this.tickets[0]?.price
  }

  get dateRange(): DateRangeEntity {
    return DateRangeEntity.fromJS({ end: getLocalTime(this.startDate), start: getLocalTime(this.endDate) })
  }

  get categoryIds() {
    return this.categories.map((category) => category.id)
  }

  queryFilter(filter: EventQuery): EventResource | undefined {
    if (filter.startDate && this.startDate < filter.startDate) return
    if (filter.endDate && this.endDate > filter.endDate) return
    if (filter.categoryId) {
      if (Array.isArray(filter.categoryId)) {
        if (!filter.categoryId.some((category) => this.categoryIds.includes(category))) return
      } else {
        const categories = filter.categoryId.toString().split(',')
        if (!categories.some((category) => this.categoryIds.includes(category))) return
      }
    }
    return this
  }

  static getById(params: Data.Identified): EventResource {
    return useResource(this.detail(), params)
  }

  static getPaginated(params: EventQuery = {}): SchemaPaginated<EventResource> {
    return useResource(this.paginated(), params)
  }

  static getAll(params: EventQuery = {}): EventResource[] {
    return useResource(this.paginated(), params).results
  }

  static getICS(params: Data.Identified): Promise<string> {
    return fetch(`/api/calendar/event/${params.id}`, { method: 'GET' }).then<string>((data) => data.text())
  }

  static register<T extends typeof Resource>(this: T) {
    const endpoint = AccountEvent.partialUpdate()
    return endpoint.extend({
      fetch: (id: Data.ID) => {
        return endpoint.fetch.call(
          endpoint.extend({
            method: 'POST',
            url: () => `${this.urlRoot}/${id}/register`,
          }),
          null,
          {},
        )
      },
      schema: AccountEvent,
    })
  }
}

export { EventResource, EventCartItem, Ticket }

import { LoadingOutlined } from '@ant-design/icons'
import { Button as Wrapper } from 'antd'
import { ButtonProps as AntButtonProps } from 'antd/lib/button'
import { CSSProperties, FC } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router-dom'
import { withPrefix } from 'src/sdk/contexts/Config'
import IvyIcon from '../icon'
import { BreakpointMax } from '../screen/Breakpoint'
import './Button.less'

export type ButtonProps = Omit<AntButtonProps, 'type'> & {
  type?: 'link' | 'text' | 'ghost' | 'default' | 'primary' | 'dashed' | undefined
  wide?: boolean
  theme?: '' | 'text' | 'primary' | 'danger' | 'warning' | 'info' | 'error' | 'icon' | 'success'
  outlined?: boolean
  goTo?: string
  ref?: React.RefObject<HTMLElement>
}

const Button: FC<ButtonProps> = ({
  type,
  ref,
  wide,
  title,
  goTo,
  children,
  theme = '',
  outlined = false,
  ...props
}) => {
  const isMobile = useMediaQuery({ maxWidth: BreakpointMax.SM })
  const history = useHistory()
  const classArray = [
    props.className,
    wide ? 'wide' : '',
    withPrefix(theme && `btn-theme-${theme}`, outlined ? `btn-theme-outlined` : ''),
  ]

  return (
    <Wrapper
      ref={ref}
      block={props.block ? props.block : wide ? isMobile : false}
      onClickCapture={(evt) => {
        goTo && history.push(goTo)
        props.onClickCapture && props.onClickCapture(evt)
      }}
      {...props}
      type={type ?? 'primary'}
      className={classArray.join(' ')}
    >
      {title ?? children}
    </Wrapper>
  )
}

type ButtonIconProps = Omit<AntButtonProps, 'icon'> & {
  icon: Design.IvyIcon
  loading?: boolean
  iconStyle?: CSSProperties
  iconSize?: number | null
}

const ButtonIcon: FC<ButtonIconProps> = ({
  icon,
  loading,
  children,
  iconSize = 24,
  iconStyle,
  className,
  ...props
}) => {
  return (
    <Wrapper
      className={withPrefix('btn-icon', className ?? '')}
      onClickCapture={(evt) => {
        props.onClickCapture && props.onClickCapture(evt)
      }}
      icon={
        loading ? (
          <LoadingOutlined spin />
        ) : (
          <IvyIcon size={iconSize !== null ? iconSize : undefined} type={icon} style={iconStyle} />
        )
      }
      {...props}
    >
      {children}
    </Wrapper>
  )
}

type ButtonLinkProps = {} & AntButtonProps
const ButtonLink: FC<ButtonLinkProps> = ({ children, ...props }) => {
  return (
    <Wrapper className={withPrefix('btn-link', 'btn-link-anchor')} {...props}>
      {children}
    </Wrapper>
  )
}

Button.defaultProps = {
  type: 'primary',
  htmlType: 'submit',
}

export { Button, ButtonIcon, ButtonLink }

import { ApiResource } from './entity'
import { BankAccount } from './wallet/bankaccount'
import { CreditCardEntity } from './wallet/creditcard'

export type SubscriptionStatus = '' | 'active' | 'cancelled' | 'expired' | 'failed' | 'pending'
type SubscriptionFrequency = '' | 'daily' | 'weekly' | 'monthly' | 'quarterly' | 'semi' | 'annually'
type SubscriptionType = 'donation' | 'fundraiser' | 'membership' | 'product' | 'service'

type SubscriptionLines = {
  readonly amount: number
  readonly tax?: number
  readonly service?: number
}

class SubscriptionEntity extends ApiResource {
  static readonly urlRoot = `/api/account/subscriptions`
  readonly autoCharge: boolean = true
  readonly createdOn: Date = new Date(0)
  readonly customerAddressId: number = 0
  readonly customerId: number = 0
  readonly details: string = ''
  readonly frequency: SubscriptionFrequency = ''
  readonly id: number = 0
  readonly lastDate: Date = new Date()
  readonly lastAmount?: number
  readonly nextDate: Date = new Date()
  readonly quantity: number = 0
  readonly rate: number = 0
  readonly taxRate: number = 0
  readonly referenceId: number = 0
  readonly type?: SubscriptionType
  readonly remainingPayments: number = 0
  readonly renewalDate: Date = new Date(0)
  readonly renewable: boolean = true
  readonly requestedCancelDate: Date = new Date(0)
  readonly serviceId: number = 0
  readonly shippingCourier: string = ''
  readonly shippingMethod: string = ''
  readonly status: SubscriptionStatus = ''
  readonly totalPayments: number = 0
  readonly paymentCount: number = 0
  readonly invoiceEnabled: boolean = false
  readonly wallet?: BankAccount | CreditCardEntity
  readonly lines: SubscriptionLines = {
    amount: 0,
  }

  get paymentMethod(): BankAccount | CreditCardEntity | undefined {
    if (this.wallet) {
      return this.wallet.paymentType === 'creditcard'
        ? CreditCardEntity.fromJS(this.wallet)
        : BankAccount.fromJS(this.wallet)
    }
    return this.wallet
  }

  get madePayment(): boolean {
    return this.lastDate.toString() !== '1900-01-01T00:00:00' && this.lastAmount !== undefined && this.lastAmount > 0
  }

  get pastDue(): boolean {
    return this.nextDate < new Date()
  }

  get period(): string {
    switch (this.frequency) {
      case 'daily':
        return 'day'
      case 'weekly':
        return 'week'
      case 'monthly':
        return 'month'
      case 'quarterly':
        return 'quarter'
      case 'semi':
        return '6 months'
      case 'annually':
        return 'year'
      default:
        return ''
    }
  }

  get periodPlural(): string {
    switch (this.frequency) {
      case 'daily':
        return 'days'
      case 'weekly':
        return 'weeks'
      case 'monthly':
        return 'months'
      case 'quarterly':
        return 'quarters'
      case 'semi':
        return '6 month periods'
      case 'annually':
        return 'years'
      default:
        return ''
    }
  }
}

export { SubscriptionEntity }

export type RouteItem = {
  exact: boolean
  path: string
  component: string
  membersOnly: boolean
  plusPlan: boolean
}

type RouteRedirectItem = {
  from: string
  to: string
}

export const RouteMap = {
  activate: '/activate/:code',
  account: '/account',
  accountEvent: '/account/event/:id/:action?',
  accountTab: '/account/:tab?/:subTab?/:id?',
  accountWallet: '/account/wallet/:subTab?/:id?',
  accountWalletTickets: '/account/wallet/tickets',
  accountMembershipTab: '/account/memberships',
  accountBilling: '/account/billing/:billingTab?/:id?',
  accountInvoices: '/account/billing/invoices',
  accountInvoice: '/account/billing/invoices/:id',
  accountTransactions: '/account/billing/transactions',
  accountTransaction: '/account/billing/transactions/:id',
  accountOrders: '/account/billing/orders',
  accountOrder: '/account/billing/orders/:id',
  accountPerksTab: '/account/perks',
  accountProfileTab: '/account/profile',
  activity: '/activities',
  activityId: '/activities/:id',
  appointments: '/appointments',
  appointment: '/appointments/:id',
  blog: '/blogs',
  blogId: '/blogs/:id',
  calendarRedirect: '/redirect/calendar/:type',
  checkout: '/checkout',
  clubBenefits: '/club-benefits',
  generalError: '/error/:response?',
  register: '/register',
  dinings: '/dining',
  dining: '/dining/:id(\\d+)',
  diningCategory: '/dining/categories',
  diningMenu: '/dining/menu',
  directory: '/directory',
  // directoryMembers: '/directory/members',
  // directoryBusiness: '/directory/businesses',
  directoryTab: '/directory/:tab?/:id?',
  actions: '/actions/:action?',
  event: '/events',
  eventId: '/events/:id',
  // faq: '/faq',
  forgotPassword: '/forgot-password',
  guestList: '/guest-pass',
  guestPassShare: '/guest-pass/:id/:pin?',
  guestTicket: '/guest-tickets/:serial',
  guestVoucher: '/guest-vouchers/:id/:pin',
  index: '/',
  notifications: '/notifications',
  notificationsTab: '/notifications/:tab',
  messages: '/messages',
  mySchedule: '/my-schedule',
  myScheduleReservations: '/my-schedule/reservations/:id',
  myClub: '/my-club',
  pageId: '/pages/:id',
  passbookDownload: '/passbook/:id',
  rooms: '/rooms',
  room: '/rooms/:id(\\d+)',
  shop: '/product',
  shopCategory: '/product/categories',
  shopMenu: '/product/menu',
  spas: '/spa-wellness',
  spa: '/spa-wellness/:id',
  userLogin: '/login',
  userLogout: '/logout',
  userPasswordReset: '/password-reset',
  verifyEmail: '/verify-email',
} as const

export const MappedRoutes: RouteItem[] = [
  {
    exact: true,
    path: RouteMap.account,
    component: 'account/Account',
    membersOnly: false,
    plusPlan: false,
  },
  {
    exact: true,
    path: RouteMap.accountEvent,
    component: 'account/Event',
    membersOnly: false,
    plusPlan: false,
  },
  {
    exact: true,
    path: RouteMap.accountTab,
    component: 'account/Account',
    membersOnly: false,
    plusPlan: false,
  },
  {
    exact: true,
    path: RouteMap.accountTransactions,
    component: 'account/Transactions',
    membersOnly: false,
    plusPlan: false,
  },
  {
    exact: true,
    path: RouteMap.activity,
    component: 'activities/Activities',
    membersOnly: false,
    plusPlan: true,
  },
  // {
  //   exact: true,
  //   path: RouteMap.cart,
  //   component: 'cart/Cart',
  //   membersOnly: false,
  //   plusPlan: true,
  // },
  {
    exact: true,
    path: RouteMap.checkout,
    component: 'checkout/Checkout',
    membersOnly: false,
    plusPlan: true,
  },
  {
    exact: false,
    path: `${RouteMap.clubBenefits}/:tab?`,
    component: 'perks/Perks',
    membersOnly: false,
    plusPlan: true,
  },
  {
    exact: true,
    path: `${RouteMap.dinings}`,
    component: 'dining/restaurants/Restaurants',
    membersOnly: false,
    plusPlan: true,
  },
  {
    exact: true,
    path: `${RouteMap.dining}`,
    component: 'dining/restaurants/Restaurant',
    membersOnly: false,
    plusPlan: true,
  },
  {
    exact: true,
    path: RouteMap.event,
    component: 'event/Events',
    membersOnly: false,
    plusPlan: true,
  },
  {
    exact: true,
    path: RouteMap.eventId,
    component: 'event/Event',
    membersOnly: false,
    plusPlan: true,
  },
  {
    exact: true,
    path: '/event/:id',
    component: 'event/Event',
    membersOnly: false,
    plusPlan: true,
  },
  {
    exact: true,
    path: RouteMap.guestList,
    component: 'guest-list/GuestList',
    membersOnly: false,
    plusPlan: true,
  },
  {
    exact: false,
    path: `${RouteMap.messages}/:id?`,
    component: 'messaging/Messaging',
    membersOnly: false,
    plusPlan: true,
  },
  {
    exact: true,
    path: RouteMap.shopCategory,
    component: 'store/Category',
    membersOnly: false,
    plusPlan: true,
  },
  {
    exact: true,
    path: RouteMap.shopMenu,
    component: 'store/Menu',
    membersOnly: false,
    plusPlan: true,
  },
  {
    exact: true,
    path: RouteMap.shop,
    component: 'store/Promo',
    membersOnly: false,
    plusPlan: true,
  },
  {
    exact: true,
    path: RouteMap.userLogout,
    component: 'user/Logout',
    membersOnly: false,
    plusPlan: false,
  },
  {
    exact: true,
    path: RouteMap.appointments,
    component: 'appointment/Appointments',
    membersOnly: false,
    plusPlan: true,
  },
  {
    exact: true,
    path: RouteMap.appointment,
    component: 'appointment/Appointment',
    membersOnly: false,
    plusPlan: true,
  },
  {
    exact: false,
    path: RouteMap.blogId,
    component: 'blog/Post',
    membersOnly: false,
    plusPlan: true,
  },
  {
    exact: false,
    path: '/blog/:id',
    component: 'blog/Post',
    membersOnly: false,
    plusPlan: true,
  },
  {
    exact: false,
    path: `${RouteMap.index}/:id(\\s+)`,
    component: 'index',
    membersOnly: false,
    plusPlan: true,
  },
  {
    exact: true,
    path: RouteMap.notifications,
    component: 'notifications/Notifications',
    membersOnly: false,
    plusPlan: false,
  },
  {
    exact: false,
    path: RouteMap.mySchedule,
    component: 'mySchedule/MySchedule',
    membersOnly: false,
    plusPlan: false,
  },
  {
    exact: true,
    path: '/',
    component: 'index/MyClub',
    membersOnly: false,
    plusPlan: false,
  },
  {
    exact: true,
    path: RouteMap.myClub,
    component: 'index/MyClub',
    membersOnly: false,
    plusPlan: false,
  },
  {
    exact: true,
    path: RouteMap.pageId,
    component: 'pages/Page',
    membersOnly: false,
    plusPlan: true,
  },
  {
    exact: true,
    path: RouteMap.rooms,
    component: 'rooms/Rooms',
    membersOnly: false,
    plusPlan: true,
  },
  {
    exact: true,
    path: RouteMap.room,
    component: 'rooms/Room',
    membersOnly: false,
    plusPlan: true,
  },
  // {
  //   exact: false,
  //   path: `${RouteMap.shop}/:id(\\d+)`,
  //   component: 'store/Store',
  //   membersOnly: false,
  //   plusPlan: true,
  // },
  {
    exact: true,
    path: RouteMap.spas,
    component: 'spa/Spas',
    membersOnly: true,
    plusPlan: true,
  },
  {
    exact: true,
    path: RouteMap.spa,
    component: 'spa/Spa',
    membersOnly: true,
    plusPlan: true,
  },
  {
    exact: true,
    path: `${RouteMap.directory}/:tab?`,
    component: 'directory/Directories',
    membersOnly: true,
    plusPlan: true,
  },
  {
    exact: true,
    path: `${RouteMap.directory}/:tab/:id`,
    component: 'directory/Directory',
    membersOnly: true,
    plusPlan: true,
  },
  {
    exact: false,
    path: RouteMap.guestTicket,
    component: 'public/guest-ticket/GuestTicket',
    membersOnly: false,
    plusPlan: false,
  },
  {
    exact: true,
    path: `/privacy`,
    component: 'privacy/PrivacyPolicy',
    membersOnly: false,
    plusPlan: false,
  },
  {
    exact: true,
    path: `/terms`,
    component: 'index/Terms',
    membersOnly: false,
    plusPlan: false,
  },
  {
    exact: false,
    path: `/faq/:arg?`,
    component: 'faq/Faq',
    membersOnly: false,
    plusPlan: false,
  },
  {
    exact: true,
    path: `/cookie`,
    component: 'index/Cookie',
    membersOnly: false,
    plusPlan: false,
  },
]

export const RouteRedirects: RouteRedirectItem[] = [
  {
    from: '/event/*',
    to: RouteMap.eventId,
  },
]

import { Col, Row, Typography } from 'antd'
import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { RouteMap } from 'src/containers/RouteMap'
import { ApiError } from 'src/sdk/api'
import { Button } from 'src/sdk/components/form'
import { CenteredContent } from 'src/sdk/components/layout'
import { VerticalSpace } from 'src/sdk/components/layout/Grid'
import { SectionLoader } from 'src/sdk/components/loader'
import { Result } from 'src/sdk/components/result/Result'
import MultiSurvey from 'src/sdk/components/survey/MultiSurvey'
import { useDynamicDrawer } from 'src/sdk/contexts/DynamicDrawer'
import { OrderEntity } from 'src/sdk/datasource/order'
import { SurveyEntity } from 'src/sdk/datasource/survey'
import { setSkipForm, skipFormSelector } from 'src/sdk/store/checkoutSlice'

import { useStripe } from '@stripe/react-stripe-js'
import { useHistory } from 'react-router'
import { Alert } from 'src/sdk/components/alert/Alert'
import { useFeatureToggles } from 'src/sdk/contexts/Feature'
import { useAppDispatch } from 'src/sdk/store'
import './BillingStep.less'

type OrderSuccessProps = {
  order: OrderEntity
  requiredSurveys: (required: boolean) => void
}
const OrderSuccess: FC<OrderSuccessProps> = ({ order, requiredSurveys }) => {
  const skipForm = useSelector(skipFormSelector)
  const [loading, setLoading] = useState(false)
  const hasSurveys = order.surveys ? order.surveys.length > 0 : false
  const [surveysComplete, setSurveysComplete] = useState(!hasSurveys)

  const onReady = (surveys?: SurveyEntity[]) => {
    if (!surveys) return
    const hasRequired = surveys.flat().some((s) => s.fields.some((f) => f.field.required))
    setSurveysComplete(false)
    requiredSurveys(hasRequired)
  }

  const handleComplete = () => {
    setSurveysComplete(true)
    requiredSurveys(false)
  }

  return loading ? (
    <SectionLoader />
  ) : (
    <Row gutter={[32, 32]} justify={'center'} align={'top'}>
      <Col span={24} lg={16}>
        {(surveysComplete || skipForm) && (
          <Result.PaymentCompleted
            type={order.hasReservations ? 'reservation' : undefined}
            subTitle={'You will receive an email confirmation shortly.'}
          >
            <Typography.Title level={5}>Order confirmation #: {order.id}</Typography.Title>
            <VerticalSpace>
              <Button block key={'view-my-club'} goTo={RouteMap.index} type={'ghost'}>
                Back To My Club
              </Button>
              {order.hasTickets && (
                <Button block key={'view-tickets'} goTo={RouteMap.accountWalletTickets} type={'primary'}>
                  View Event Tickets
                </Button>
              )}
              {(order.hasReservations || order.hasAppointments) && (
                <Button block key={'view-bookings'} goTo={RouteMap.mySchedule} type={'primary'}>
                  View My Schedule
                </Button>
              )}
            </VerticalSpace>
          </Result.PaymentCompleted>
        )}
        {order.surveys && !surveysComplete && !skipForm && (
          <div style={{ marginBottom: 120 }}>
            <Typography.Title level={3}>A few important questions before you go...</Typography.Title>
            <MultiSurvey onReady={onReady} onComplete={handleComplete} items={order.surveys} onLoading={setLoading} />
          </div>
        )}
      </Col>
    </Row>
  )
}

const OrderError: FC<{ error: ApiError }> = ({ error }) => {
  const { setDrawerVisible } = useDynamicDrawer()
  const ErrorDetails: FC = () => {
    switch (error.error) {
      case 'card_declined':
        return (
          <Typography.Text>Your payment was declined. Please contact your bank to resolve this issue.</Typography.Text>
        )
      case 'checkout_empty':
        return <Typography.Text>You have no items in your shopping cart.</Typography.Text>
      default:
        return error.errorDetails !== undefined ? (
          Array.isArray(error.errorDetails) ? (
            <VerticalSpace align={'center'}>
              {error.errorDetails.map((item) => (
                <Typography.Text type={'danger'}>{item.details}</Typography.Text>
              ))}
            </VerticalSpace>
          ) : (
            <Typography.Text type={'danger'}>{error.errorDetails.details}</Typography.Text>
          )
        ) : (
          <Alert type={'error'} message={error.title} description={error.errorDescription} />
        )
    }
  }
  return (
    <Result.PaymentFailed title={error.error === 'card_declined' ? 'Payment Failed' : 'Checkout Failed'}>
      <VerticalSpace size={32}>
        <ErrorDetails />
        {error.error !== 'checkout_empty' && (
          <Button type={'primary'} onClick={() => setDrawerVisible(false)} block>
            Try Again
          </Button>
        )}
      </VerticalSpace>
    </Result.PaymentFailed>
  )
}

export default function useProcessPayment() {
  const { hasStripeProcessor } = useFeatureToggles()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { setDrawer, setDrawerCloseable, setDrawerCloseCallback } = useDynamicDrawer()

  const HandleSuccess = (order: OrderEntity, afterShow?: () => void) => {
    
    dispatch(setSkipForm(false))
    setDrawer({
      afterVisibleChange: (visible) => {
        if (afterShow && visible) {
          afterShow()
        }
      },
      content: (
        <CenteredContent>
          <OrderSuccess
            requiredSurveys={(required) => {
              if (required) {
                setDrawerCloseCallback(() => () => {
                  dispatch(setSkipForm(true))
                  history.push(RouteMap.mySchedule)
                })
              } else {
                setDrawerCloseCallback(() => {
                  history.push(RouteMap.mySchedule)
                })
              }
              setDrawerCloseable(!required)
            }}
            order={OrderEntity.fromJS(order)}
          />
        </CenteredContent>
      ),
    })
  }

  const HandleError = (error: ApiError, afterShow?: () => void) => {
    setDrawer({
      afterVisibleChange: (visible) => {
        if (afterShow && visible) {
          afterShow()
        }
      },
      content: <OrderError error={error} />,
    })
  }

  const handle3dSecure = async (paymentIntentToken: string) => {
    const StripeObj = hasStripeProcessor ? useStripe() : undefined
    return StripeObj?.handleCardAction(paymentIntentToken).then(({ error, paymentIntent }) => {
      return {
        paymentIntentId: paymentIntent?.id,
        error: error,
      }
    })
  }
  return {
    HandleError,
    HandleSuccess,
    handle3dSecure,
  }
}

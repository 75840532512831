import { Typography } from 'antd'
import { TextProps } from 'antd/lib/typography/Text'
import { FC } from 'react'

type SmallTextProps = {
  align?: 'left' | 'center' | 'right'
  block?: boolean
} & TextProps

const SmallText: FC<SmallTextProps> = ({ align, block, children, ...props }) => (
  <Typography.Text style={{ fontSize: 12, textAlign: align, display: block ? 'block' : undefined }} {...props}>
    {children}
  </Typography.Text>
)

export default SmallText

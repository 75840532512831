import { Select } from 'antd'
import { notification } from 'antd/es'
import { FC, Suspense, useEffect, useMemo, useState } from 'react'
import { useDLE } from 'rest-hooks'
import { Button } from 'src/sdk/components/form'
import { CenteredContent, HorizontalSpace, VerticalSpace } from 'src/sdk/components/layout'
import { Section } from 'src/sdk/components/layout/Section'
import { OverlayLoader, SectionLoader } from 'src/sdk/components/loader'
import { Money } from 'src/sdk/components/text'
import { useDynamicDrawer } from 'src/sdk/contexts/DynamicDrawer'
import { useNotification } from 'src/sdk/contexts/Notification'
import { PaymentMethod } from 'src/sdk/datasource/payment'
import { TransactionEntity, TransactionPaymentResponse } from 'src/sdk/datasource/transaction'
import { Capitalize } from 'src/sdk/helpers/strings'
import { WalletItem } from 'src/sdk/datasource/wallet/wallet'
import useWallet from 'src/sdk/hooks/useWallet'
import PaymentMethodAdd from '../account/tabs/wallet/payment-methods/PaymentMethodAdd'
import './InvoicePay.less'
import Modal from 'src/sdk/components/modal/Modal'
import { CreditCardEntity } from 'src/sdk/datasource/wallet/creditcard'
import { usePaymentEndpoint } from 'src/sdk/hooks/usePaymentEndpoint'
import Descriptions, { DescriptionsItem } from 'src/sdk/components/table/Descriptions'

type InvoicePayButtonProps = {
  transaction: TransactionEntity
  onComplete?: (transaction: TransactionEntity) => void
}

const InvoicePayButton: FC<InvoicePayButtonProps> = ({ transaction, onComplete }) => {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [closeable, setCloseable] = useState(true)
  // Keep wallet here to pre-load the payment methods
  // Pay Invoice Modal will force a re-render and flicker without this pre-load
  const { wallet } = useWallet()

  const hanadleCancel = () => {
    setVisible(false)
    setLoading(false)
  }
  const handleComplete = (transaction: TransactionEntity) => {
    onComplete && onComplete(transaction)
    setVisible(false)
  }

  const handlePaying = () => {
    setCloseable(false)
  }

  const InvoicePayMemo = useMemo(
    () => (
      <InvoicePay
        transaction={transaction}
        onCancel={hanadleCancel}
        onComplete={handleComplete}
        onPaying={handlePaying}
      />
    ),
    [transaction],
  )
  return (
    <>
      <Button style={{ marginTop: 20 }} loading={loading} onClick={() => setVisible(true)} type={'primary'} block>
        Pay Invoice
      </Button>
      <Modal centered destroyOnClose footer={null} visible={visible} closable={false} maskClosable={closeable}>
        {InvoicePayMemo}
      </Modal>
    </>
  )
}

type InvoicePayProps = {
  transaction: TransactionEntity
  onPaying: (paying: boolean) => void
  onCancel: () => void
  onComplete: (transaction: TransactionEntity) => void
}
const InvoicePay: FC<InvoicePayProps> = ({ transaction, onCancel, onComplete, onPaying }) => {
  const { id, amount } = transaction
  const { data: cards, loading: walletLoading } = useDLE(CreditCardEntity.list(), {})
  const { createPaymentEndpoint, submitPayment } = usePaymentEndpoint<TransactionPaymentResponse>()
  const { notifyOnError } = useNotification()
  const { setDrawer, setDrawerVisible } = useDynamicDrawer()
  const [balanceDue, setBalanceDue] = useState(amount)
  const [paying, setPaying] = useState(false)
  const [walletId, setWalletId] = useState<Data.ID>()
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>()
  const [disabled, setDisabled] = useState(true)

  const InvoicePay = createPaymentEndpoint({
    url: `${TransactionEntity.urlRoot}/${id}/pay`,
    schema: {
      transaction: TransactionEntity,
    },
  })

  useEffect(() => {
    setDisabled(!paymentMethod)
  }, [paymentMethod])

  const handlePayment = () => {
    if (!paymentMethod) return
    setPaying(true)
    onPaying(true)

    submitPayment(InvoicePay, {
      id,
      paymentMethod,
    })
      .then((response) => {
        onComplete(response.transaction)
        if (response.transaction.status !== 'invoiced') {
          setBalanceDue(0)
        }
        notification.success({
          message: 'Thank you for your payment',
          description: `We have successfully processed your invoice payment.  You will receive an email confirmation shortly.`,
        })
      })
      .catch(notifyOnError)
      .finally(() => {
        setPaying(false)
        onPaying(false)
      })
  }

  const handleAddPaymentMethod = () => {
    
    setDrawer({
      zIndex: 1002,
      content: (
        <CenteredContent>
          <PaymentMethodAdd
            onSuccess={(paymentMethod: WalletItem) => {
              setDrawerVisible(false)
              setWalletId(paymentMethod.id)
            }}
            onCancel={() => setDrawerVisible(false)}
          />
        </CenteredContent>
      ),
    })
  }

  const handlePaymentMethod = (value: Data.ID) => {
    setWalletId(value)
    const method = cards?.find((w) => w.id === value)
    method &&
      setPaymentMethod({
        id: method.id,
        type: method?.paymentType === 'creditcard' ? 'creditcard' : 'bankAccount',
        amount: amount,
      })
  }

  return (
    <OverlayLoader loading={paying}>
      <Section title={'Pay Invoice'} subTitle={'Select a payment method below'}>
        <VerticalSpace>
          <Descriptions bordered layout={'vertical'} column={{ xs: 1, sm: 1, md: 1, lg: 2 }}>
            <DescriptionsItem label={'Invoice #'}>{id}</DescriptionsItem>
            <DescriptionsItem label={'Balance Due'}>
              <Money>{balanceDue}</Money>
            </DescriptionsItem>
            <DescriptionsItem label={'Payment Method'}>
              <VerticalSpace>
                <Suspense fallback={<SectionLoader />}>
                  <Select<Data.ID>
                    placeholder='Select payment method'
                    showSearch={false}
                    onChange={handlePaymentMethod}
                    style={{ width: '100%' }}
                    value={walletId}
                    loading={walletLoading}
                  >
                    {cards?.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        <HorizontalSpace split={(item.nickname ?? item.brand) && '-'}>
                          {item.nickname ?? item.brand}
                          <HorizontalSpace split={'••••'}>
                            {Capitalize(item.brand)}
                            {item.lastFour}
                          </HorizontalSpace>
                        </HorizontalSpace>
                      </Select.Option>
                    ))}
                  </Select>
                </Suspense>
                <Button type={'link'} size={'small'} onClick={() => handleAddPaymentMethod()}>
                  Add new payment method
                </Button>
              </VerticalSpace>
            </DescriptionsItem>
          </Descriptions>
          <HorizontalSpace justify={'right'}>
            <Button type={'ghost'} disabled={paying} onClick={onCancel}>
              Cancel
            </Button>
            <Button type={'primary'} onClick={handlePayment} disabled={disabled || paying} loading={paying}>
              Pay
            </Button>
          </HorizontalSpace>
        </VerticalSpace>
      </Section>
    </OverlayLoader>
  )
}
export { InvoicePay as default, InvoicePayButton }

import { PageHeader as Wrapped, Typography } from 'antd'
import { Route } from 'antd/lib/breadcrumb/Breadcrumb'
import { PageHeaderProps } from 'antd/lib/page-header'
import { FC } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link, useHistory } from 'react-router-dom'
import IvyIcon from '../icon'
import { Title } from '../text'

import './Header.less'

const withFirstAndLast = (current: string, home: string | undefined, breadcrumbs: Route[]) => {
  const firstItem = home && { breadcrumbName: 'My Club', path: home }
  const lastItem = { breadcrumbName: current, path: '' }
  firstItem && breadcrumbs.unshift(firstItem)
  breadcrumbs.push(lastItem)
  return breadcrumbs
}

const BreadcrumbItem: FC<Route> = (route: Route) =>
  route.path ? (
    <Link to={route.path}>{route.breadcrumbName}</Link>
  ) : (
    <Typography.Text type={'secondary'}>{route.breadcrumbName}</Typography.Text>
  )

type HeaderProps = {
  parentBreadcrumb?: Record<string, string>
  loading?: boolean
} & PageHeaderProps

export const Header: FC<HeaderProps> = ({ parentBreadcrumb, title, extra, loading = false }) => {
  const isMobile = useMediaQuery({ maxWidth: 576 })
  const history = useHistory()
  return (
    <Wrapped
      breadcrumb={
        parentBreadcrumb
          ? {
              itemRender: BreadcrumbItem,
              separator: '/',
              routes: withFirstAndLast(
                loading ? '' : (title as string),
                undefined,
                Object.entries(parentBreadcrumb)
                  .filter(([breadcrumbName]) => breadcrumbName !== '')
                  .map<Route>(([breadcrumbName, path]) => ({
                    breadcrumbName,
                    path,
                  })),
              ),
            }
          : undefined
      }
      backIcon={parentBreadcrumb && isMobile && <IvyIcon type={'custom/arrow-back'} size={32} />}
      onBack={parentBreadcrumb && history.goBack}
      title={
        <Title style={{ marginBottom: 0 }} level={1}>
          {title}
        </Title>
      }
      extra={extra}
    />
  )
}

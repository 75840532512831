import { Typography } from 'antd'
import { FC, useState } from 'react'
import { HorizontalSpace, VerticalSpace } from 'src/sdk/components/layout'
import { Tag } from 'src/sdk/components/tag'
import SmallText from 'src/sdk/components/text/SmallText'
import { WalletBillingUse } from 'src/sdk/datasource/wallet/wallet'

interface IBillingUse {
  initialValue?: WalletBillingUse
  value: WalletBillingUse
  onChange: (value: WalletBillingUse) => void
}

const BillingUseTag: FC<{ billingUse: WalletBillingUse }> = ({ billingUse }) => {
  switch (billingUse) {
    case 'default':
      return (
        <Tag size={'small'} type={'primary'} shape={'asymmetric'}>
          Default
        </Tag>
      )
    case 'billing':
      return (
        <Tag size={'small'} type={'info'} shape={'asymmetric'}>
          Membership Dues
        </Tag>
      )
    case 'pos':
      return (
        <Tag size={'small'} type={'info'} shape={'asymmetric'}>
          Food and Beverage
        </Tag>
      )

    default:
      return null
  }
}

const BillingUse: FC<IBillingUse> = ({ initialValue, value, onChange }) => {
  const [billingUse, setBillingUse] = useState<WalletBillingUse>(value)

  const updateValue = (value: WalletBillingUse) => {
    setBillingUse(value)
    onChange(value)
  }
  return (
    <VerticalSpace>
      <Typography.Text strong>
        Use this payment method for{' '}
        {/* <Tooltip
          style={{ maxWidth: 'unset' }}
          trigger={'click'}
          title={
            <VerticalSpace>
              <HorizontalSpace align={'start'}>
                <Typography.Text strong>Default:</Typography.Text>
                <Typography.Text>This payment method will be used for all transactions.</Typography.Text>
              </HorizontalSpace>
              <HorizontalSpace align={'start'}>
                <Typography.Text strong>Membership Dues:</Typography.Text>
                <Typography.Text>This payment method will be used as a fallback for membership dues.</Typography.Text>
              </HorizontalSpace>
              <HorizontalSpace align={'start'}>
                <Typography.Text strong>Food & Beverage:</Typography.Text>
                <Typography.Text>This payment method will be used for food and beverage transactions.</Typography.Text>
              </HorizontalSpace>
            </VerticalSpace>
          }
        /> */}
      </Typography.Text>
      {initialValue === 'default' && (
        <SmallText>
          At least one payment method must be set as default. To update the billing use, a different payment method must
          be set as default.
        </SmallText>
      )}
      <HorizontalSpace wrap>
        <Tag
          size={'middle'}
          shape={'asymmetric'}
          checkable
          disabled={initialValue === 'default'}
          isChecked={billingUse === 'default'}
          onCheck={() => updateValue('default')}
        >
          Default
        </Tag>
        <Tag
          size={'middle'}
          shape={'asymmetric'}
          checkable
          disabled={initialValue === 'default'}
          isChecked={billingUse === 'billing'}
          onCheck={() => updateValue('billing')}
        >
          Membership Dues
        </Tag>
        <Tag
          size={'middle'}
          shape={'asymmetric'}
          checkable
          disabled={initialValue === 'default'}
          isChecked={billingUse === 'pos'}
          onCheck={() => updateValue('pos')}
        >
          Food & Beverage
        </Tag>
        {/* <Tag
          size={'middle'}
          shape={'asymmetric'}
          checkable
          disabled={initialValue === 'default'}
          isChecked={billingUse === ''}
          onCheck={() => updateValue('')}
        >
          Add to Wallet
        </Tag> */}
      </HorizontalSpace>
    </VerticalSpace>
  )
}

export { BillingUseTag, BillingUse as default }

import { usePDF } from '@react-pdf/renderer'
import { DocumentProps } from '@react-pdf/types'
import { ReactElement } from 'react'
import { IntlFormatter } from 'src/sdk/contexts/Config'
import { useNativeApp } from 'src/sdk/contexts/NativeApp'
import { CompanyEntity } from 'src/sdk/datasource/company'
import { usePDFDependencies } from 'src/sdk/hooks/usePDFDependencies'
import { Button } from '../form'
import { ButtonProps } from '../form/Button'
import SmallText from '../text/SmallText'
import { useIntl } from 'react-intl'

export interface IPdfDocument extends DocumentProps {
  intlFormatter: IntlFormatter
  dateFormatter: ReturnType<typeof useIntl>['formatDate']
  company: CompanyEntity
}

type IDownloadPdfButton<T> = {
  document: (({
                company,
                intlFormatter,
                dateFormatter,
              }: IPdfDocument) => ReactElement<IPdfDocument>) | ReactElement<IPdfDocument>
  title?: string
} & Omit<ButtonProps, 'url' | 'target' | 'onClick'>

function DownloadPdfButton<T>({ document, title, type, ...props }: IDownloadPdfButton<T>) {
  const { company, intlFormatter } = usePDFDependencies()
  const { isNativeApp, dispatch } = useNativeApp()
  const { formatDate } = useIntl()
  const [instance] = usePDF({
    document:
      typeof document === 'function'
        ? document({
          company,
          dateFormatter: (date: Date) => formatDate(date, { localeMatcher: 'best fit', formatMatcher: 'best fit' }),
          intlFormatter,
        })
        : document,
  })

  return isNativeApp ? (
    <SmallText>
      For PDF version of your statement please log into the web browser version of the member portal
    </SmallText>
  ) : (
    <Button
      href={instance?.url ? instance?.url : ''}
      onClick={
        isNativeApp
          ? () =>
            dispatch({
              type: 'OPEN_PDF',
              body: {},
            })
          : undefined
      }
      target={'_blank'}
      loading={instance?.loading}
      type={type ?? 'primary'}
      {...props}
    >
      {title ? title : 'Download PDF'}
    </Button>
  )
}

export default DownloadPdfButton

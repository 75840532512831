import { Typography, Carousel, Result } from 'antd'
import { CarouselRef } from 'antd/lib/carousel'
import { FC, useEffect, useRef, useState } from 'react'
import { useController, useDLE } from 'rest-hooks'
import { FormFieldValue, SurveyEndpoint, SurveyEntity } from 'src/sdk/datasource/survey'
import { Pluralize } from 'src/sdk/helpers/strings'
import { Button } from '../form'
import { SectionLoader } from '../loader'
import SurveyForm from './SurveyForm'
import './../carousel/Carousel.less'
import './MultiSurvey.less'
import Card from '../card'

type MultiSurveyProps = {
  items: SurveyEndpoint[]
  onLoading?: (loading: boolean) => void
  onReady?: (surveys?: SurveyEntity[]) => void
  onComplete?: () => void
}

const MultiSurvey: FC<MultiSurveyProps> = ({ items, onReady, onComplete, onLoading }) => {
  const { fetch } = useController()
  const slider = useRef<CarouselRef>(null)
  const [current, setCurrent] = useState(0)
  const [submitting, setSubmitting] = useState(false)
  const [complete, setComplete] = useState(false)
  const [count, setCount] = useState(0)
  const { data, loading, error } = useDLE(SurveyEntity.list(), { uids: items?.map((i) => i.uid) })

  useEffect(() => {
    if ((data || error) && onLoading) {
      onReady && onReady(data)
      onLoading(false)
    }
    if (!data) return
    setCount(data.length)
  }, [data, loading, error])

  const isActive = (index: number) => current === index

  const onFinish = async (id: Data.ID, values: FormFieldValue[]) => {
    setSubmitting(true)
    const surveyEndpoint = items.find((d) => d.id === id)
    surveyEndpoint &&
      (await fetch(SurveyEndpoint.submit(), surveyEndpoint.endpoint, values)
        .then(() => {
          if (current < count - 1) {
            slider?.current?.next()
            setCurrent(current + 1)
          } else if (current === count - 1) {
            setComplete(true)
            onComplete && onComplete()
          }
        })
        .finally(() => setSubmitting(false)))
  }

  return loading ? (
    <SectionLoader />
  ) : data ? (
    complete ? (
      <Result status={'success'} title={`Thank you for completing ${Pluralize(count, ['this form', 'these forms'])}`} />
    ) : (
      <Card className='multi-survey'>
        <Carousel ref={slider} dots={false} arrows={false}>
          {data.map((item, index) => {
            return (
              <SurveyForm
                key={`multi-survey-${item.id}`}
                title={
                  <Typography.Title level={3} type={'secondary'}>
                    {item.name}
                  </Typography.Title>
                }
                showDescription
                externalBtn={true}
                name={`multi-survey-${index}`}
                form={item}
                onFinish={(value) => onFinish(item.id, value)}
              />
            )
          })}
        </Carousel>
        {data.length > 1 && (
          <ul className='multi-survey-dots'>
            {data.map((item, index) => (
              <li
                key={index}
                className={`multi-survey-dots-item${isActive(index) ? ' multi-survey-dots-item-active' : ''}`}
              />
            ))}
          </ul>
        )}

        {data.length > 1 && current > 0 && (
          <Button type='ghost' wide className='multi-survey-button' onClick={() => setCurrent(current - 1)}>
            Back
          </Button>
        )}
        <Button
          className='multi-survey-button'
          style={{ marginTop: 24 }}
          wide
          loading={submitting}
          disabled={submitting}
          type={'primary'}
          form={`multi-survey-${current}`}
          htmlType={'submit'}
          title={current < count - 1 ? 'Continue' : 'Submit'}
        />
      </Card>
    )
  ) : null
}

export default MultiSurvey

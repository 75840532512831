import { Resource } from '@rest-hooks/rest'
import { RcFile } from 'antd/lib/upload'
import { useResource } from 'rest-hooks'
import { toBase64 } from '../helpers/file'
import { ApiResource } from './entity'

export type DataMapType =
  | ''
  | 'attribute'
  | 'first_name'
  | 'middle_name'
  | 'last_name'
  | 'gender'
  | 'company_name'
  | 'company_title'
  | 'email'
  | 'cc_email'
  | 'mobile_number'
  | 'phone_number'
  | 'address'
  | 'address2'
  | 'city'
  | 'state'
  | 'zip_code'
  | 'country'
  | 'birthdate'
  | 'anniversary_date'
  | 'website'
  | 'device_type'
  | 'opt_in_email'
  | 'opt_in_sms'
  | 'facebook_handle'
  | 'twitter_handle'
  | 'instagram_handle'
  | 'linkedin_handle'
  | 'profile_photo'
  | 'customer_reference'
  | 'customer_type'
  | 'timezone'
  | 'username'
  | 'password'

export type FieldValue = {
  text: string
  value: string
  selected: boolean
}

export type FieldType =
  | 'canvas'
  | 'radio'
  | 'checkbox'
  | 'select'
  | 'yesno'
  | 'textarea'
  | 'text'
  | 'password'
  | 'datetime'
  | 'section'
  | 'hidden'
  | 'upload'

export type Field = {
  format: string
  help: string
  label: string
  name: string
  style: string
  type: FieldType
  required: boolean
  placeholder?: string
  value?: string
  values?: FieldValue[]
}

export type SurveyField = {
  id: number
  user_id: number
  survey_id: number
  company_id: number
  dataMap: DataMapType
  order: number
  tag_header: string
  field: Field
}

export type SurveyResponse = {
  id: number
  surveyId: number
  createdOn: Date
  answers: SurveyAnswer[]
}

export type SurveyAnswer = {
  id: number
  fieldId: number
  surveyId: number
  responseId: number
  answer: string
  modifiedOn: Date
  createdOn: Date
}

class AbstractSurveyEntity extends ApiResource implements Data.Identified {
  static urlRoot = `/api/surveys`
  readonly id: Data.ID = ''
  readonly uid: string = ''
  readonly name: string = ''
  readonly description: string = ''
  readonly businessId: number = 0
  readonly buttonCreate?: string
  readonly buttonEdit: string = ''
  readonly hasSubmitted: boolean = false
  readonly canSubmit: boolean = true
  readonly confirmationHeader?: string
  readonly confirmationText?: string
  readonly multipleResponses: boolean = false
  readonly referenceId: number = 0
  readonly referenceType: string = ''
  readonly createdOn: string = ''
  readonly endpoint: string = ''
  fields: SurveyField[] = []
  responses: SurveyResponse[] = []

  get hasRequiredFields() {
    return this.fields.some((i) => i.field.required)
  }

  static async parseValues(values: FormFieldValue[]) {
    // Creates an array of promises
    // Handles converting files to base64 and replacing the answer with the base64 value
    // Standard form answers get returned with their original value
    const valuePromises = values.map((value) => {
      return new Promise<FormFieldValue>((resolve) => {
        if (typeof value.answer === 'object' && 'file' in value.answer) {
          return toBase64(value.answer.file).then((result) =>
            resolve({
              field_id: value.field_id,
              answer: result,
            } as FormFieldValue),
          )
        } else {
          resolve(value)
        }
      })
    })

    return await Promise.all(valuePromises)
  }
}

class SurveyEntity extends AbstractSurveyEntity {
  static getById(params: Data.Identified): SurveyEntity {
    return useResource(this.detail(), params)
  }

  static get<T extends typeof Resource>(this: T) {
    return this.detail().extend({
      schema: this,
    })
  }

  static getMany(params: Data.ID[]): SurveyEntity[] {
    return useResource(this.list(), params)
  }

  static async submit(id: Data.ID, fieldValues: FormFieldValue[], url?: string): Promise<any> {
    const values = await this.parseValues(fieldValues)
    return await super.fetch(url ? url : `${this.urlRoot}/${id}`, {
      method: 'POST',
      body: JSON.stringify(values),
    })
  }

  static endpointSend<T extends typeof Resource>(this: T) {
    const create = this.create()
    return create.extend({
      fetch: async (endpoint: string, fieldValues: FormFieldValue[]) => {
        const values = await SurveyEntity.parseValues(fieldValues)
        return create.fetch.call(create.extend({
          url: () => `/api/surveys/${endpoint}`,
        }), null, values,
        )
      },
    })
  }
}

class PublicSurveyEntity extends SurveyEntity {
  static urlRoot = '/api/public/surveys/'
}

export class FormFieldFileValue {
  file?: RcFile
  fileList?: RcFile[]
}

export type FormFieldValue = {
  field_id: number
  type?: FieldType
  answer: string | string[] | number | FormFieldFileValue
}

type ExperienceType =
  | 'privacy-policy'
  | 'contact'
  | 'register'
  | 'club-visit'
  | 'preferences'
  | 'points-missing'
  | 'reviews'
  | 'restaurant'
  | 'review_business'
  | 'guest-pass'
  | 'nominate-member'
  | 'other'

class ExperienceSurveyEntity extends AbstractSurveyEntity {
  static urlRoot = '/api/surveys/experiences'

  static getByReferenceType(referenceType: ExperienceType): SurveyEntity {
    return useResource(this.detail(), { id: referenceType })
  }

  static guestPassSurvey(passId: Data.ID): SurveyEntity {
    return useResource(
      this.detail().extend({
        url: () => `${this.urlRoot}/guest-pass?reference_id=${passId}`,
      }),
      {},
    )
  }

  static get(referenceType: ExperienceType): SurveyEntity {
    return useResource(this.detail(), { id: referenceType })
  }

  static fetchPublicForm<T extends typeof Resource>(this: T) {
    const endpoint = this.detail()
    return endpoint.extend({
      fetch: (referenceType: ExperienceType) => {
        return endpoint.fetch.call(
          endpoint.extend({
            url: () => `/api/public/surveys/experiences/${referenceType}`,
          }),
          {},
        )
      },
      key(params) {
        return `/api/public/surveys/experiences/${params.id}`
      },
      schema: this,
    })
  }

  static async getPublic(referenceType: ExperienceType, id: Data.ID) {
    return await this.query(`/api/public/surveys/experiences/${referenceType}`, { referenceId: id })
  }

  static async submit(
    referenceType: ExperienceType,
    fieldValues: FormFieldValue[],
    params: Record<string, any> = {},
  ): Promise<any> {
    const values = await AbstractSurveyEntity.parseValues(fieldValues)
    const queryString = AbstractSurveyEntity.queryString(params)

    return await super.fetch(`${this.urlRoot}/${referenceType}${queryString && `?${queryString}`}`, {
      method: 'POST',
      body: JSON.stringify(values),
    })
  }

  static async publicSubmit(
    referenceType: ExperienceType,
    fieldValues: FormFieldValue[],
    params: Record<string, any> = {},
  ): Promise<any> {
    const values = await AbstractSurveyEntity.parseValues(fieldValues)
    const queryString = AbstractSurveyEntity.queryString(params)

    return await super.fetch(`/api/public/surveys/experiences/${referenceType}${queryString && `?${queryString}`}`, {
      method: 'POST',
      body: JSON.stringify(values),
    })
  }
}

class SurveyEndpointResource extends SurveyEntity {
  pk(): string | undefined {
    return this.uid
  }

  static get key() {
    return '/api/account/surveys'
  }

  static send<T extends typeof Resource>(this: T) {
    const create = this.create()
    return create.extend({
      fetch: async (endpoint: string, formValues: FormFieldValue[]) => {
        const values = await SurveyEntity.parseValues(formValues)
        return create.fetch.call(
          create.extend({
            url: () => endpoint,
          }),
          null,
          values,
        )
      },
      schema: this,
    })
  }

  static sendPublic<T extends typeof Resource>(this: T) {
    const create = this.create()
    return create.extend({
      fetch: async (uid: string, formValues: FormFieldValue[]) => {
        const values = await SurveyEntity.parseValues(formValues)
        return create.fetch.call(
          create.extend({
            url: () => `/api/public/surveys/${uid}`,
          }),
          null,
          values,
        )
      },
      schema: this,
    })
  }
}

class SurveyEndpoint extends ApiResource {
  static urlRoot = ''
  readonly id: number = 0
  readonly uid: string = ''
  readonly endpoint: string = ''
  readonly referenceType: string = ''
  readonly referenceId: number = 0

  pk(parent?, key?): string | undefined {
    return `${this.uid}`
  }

  static get key() {
    return 'SurveyEndpoint'
  }

  static get<T extends typeof Resource>(this: T) {
    const detail = this.detail()
    return detail.extend({
      fetch: async (endpoint: string) => {
        return await detail.fetch.call(
          detail.extend({
            url: () => endpoint,
          }),
          {},
        )
      },
      schema: SurveyEntity,
    })
  }

  static submit<T extends typeof Resource>(this: T) {
    const create = this.create()
    return create.extend({
      fetch: async (endpoint: string, fieldValues: FormFieldValue[]) => {
        const values = await SurveyEntity.parseValues(fieldValues)
        return create.fetch.call(
          create.extend({
            url: () => endpoint,
          }),
          null,
          values,
        )
      },
    })
  }
}

export {
  AbstractSurveyEntity,
  SurveyEntity,
  ExperienceSurveyEntity,
  SurveyEndpoint,
  PublicSurveyEntity,
  SurveyEndpointResource,
}

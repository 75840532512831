import { Col, Form, Input, notification, Row, Typography } from 'antd'
import { ChangeEvent, FC, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useController } from 'rest-hooks'
import { CreditCard } from 'src/components/account/cards/CreditCard'
import { Button, Item } from 'src/sdk/components/form'
import { FormItem } from 'src/sdk/components/form/FormItem'
import { LabelTooltip } from 'src/sdk/components/form/LabelToolTip'
import { VerticalSpace } from 'src/sdk/components/layout'
import { OverlayLoader } from 'src/sdk/components/loader'
import { PopConfirm } from 'src/sdk/components/popconfirm/PopConfirm'
import { useNotification } from 'src/sdk/contexts/Notification'
import { CreditCardEntity, CreditCardUpdate } from 'src/sdk/datasource/wallet/creditcard'
import { WalletBillingUse } from 'src/sdk/datasource/wallet/wallet'
import useWallet from 'src/sdk/hooks/useWallet'
import BillingUse from '../BillingUse'

export type CardEditValues = {
  card_exp: string
} & CreditCardUpdate

type CreditCardViewProps = {
  onDelete?: (id: Data.ID) => void
} & Data.Source<CreditCardEntity>

const CreditCardView: FC<CreditCardViewProps> = ({ data, onDelete }) => {
  const [form] = Form.useForm()
  const { onUpdate } = useWallet()
  const { id, fullExpiration } = CreditCardEntity.fromJS(data)
  const [billingUse, setBillingUse] = useState<WalletBillingUse>(data.billingUse)
  const { fetch } = useController()
  const { notifyOnError } = useNotification()
  const [loading, setLoading] = useState(false)
  const [country, setCountry] = useState(data.billingDetails.country)
  const onExpChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const value = evt.target.value.replace('/', '').replaceAll(' ', '')
    const length = value.length
    if (length >= 2) {
      const newDate = `${value.slice(0, 2)} / ${value.slice(2, length > 4 ? 4 : length)}`
      form.setFieldsValue({ card_exp: newDate })
    }
  }
  const updateCard = ({ card_exp, ...rest }: CardEditValues) => {
    if (!rest.billingDetails.country) {
      notification.error({
        message: 'Please select a country',
        description: 'Please select the billing country of this payment method.',
      })
      return
    }
    setLoading(true)
    const exp_month = card_exp.split('/')[0].trim()
    const exp_year = card_exp.split('/')[1].trim()

    fetch(
      CreditCardEntity.partialUpdate(),
      { id: id },
      {
        ...rest,
        expMonth: exp_month,
        expYear: exp_year,
        billingUse: billingUse,
      },
    )
      .then((response) => {
        notification.success({ message: 'Credit card successfully updated' })
        onUpdate(response)
      })
      .catch(notifyOnError)
      .finally(() => {
        setLoading(false)
      })
  }

  const handleDelete = () => {
    setLoading(true)
    fetch(CreditCardEntity.delete(), { id: id })
      .then(() => {
        notification.success({ message: 'Credit card successfully deleted' })
        onDelete && onDelete(id)
      })
      .catch(notifyOnError)
      .finally(() => setLoading(false))
  }

  const CardBlock = () => {
    const isMobile = useMediaQuery({ maxWidth: 991 })
    return (
      <VerticalSpace size={12} style={{ marginBottom: isMobile ? 100 : 0 }}>
        <PopConfirm
          title={'Are you sure you want to delete this payment method?'}
          iconType={'symbol/exclamation-circle'}
          okText={'Yes'}
          cancelText={'Nevermind'}
          onConfirm={handleDelete}
        >
          <Button block type={'ghost'}>
            Delete
          </Button>
        </PopConfirm>
        <Button block htmlType={'submit'} type={'primary'}>
          Update
        </Button>
      </VerticalSpace>
    )
  }

  const CreditCardViewForm = () => {
    return (
      <VerticalSpace size={24}>
        <BillingUse initialValue={data.billingUse} value={billingUse} onChange={setBillingUse} />
        <VerticalSpace size={0}>
          <Item
            name='nickname'
            label={
              <LabelTooltip
                label={'Card Nickname'}
                helpText={'Enter a nickname to easily identify this payment method in the future'}
              />
            }
          >
            <Input placeholder={'Nickname'} />
          </Item>

          <Row gutter={[8, 0]}>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Item name='card_exp' label='Exp Date'>
                <Input onChange={onExpChange} placeholder={'MM / YY'} />
              </Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <FormItem.ZipCode name={['billingDetails', 'zipCode']} country={country} />
            </Col>
            <Col span={24}>
              <Item name={['billingDetails', 'country']} label={'Country'}>
                <FormItem.Countries onChange={setCountry} />
              </Item>
            </Col>
          </Row>

          <Item
            name={['billingDetails', 'name']}
            label={
              <LabelTooltip
                label={'Name on card'}
                helpText={'Enter your first and last name exactly as they appear on your billing statement'}
              />
            }
          >
            <Input placeholder={'Enter name on card'} />
          </Item>
        </VerticalSpace>
      </VerticalSpace>
    )
  }

  return (
    <OverlayLoader loading={loading}>
      <Form<CardEditValues>
        initialValues={{
          ...data,
          card_exp: fullExpiration,
        }}
        onFinish={updateCard}
        form={form}
      >
        <Row
          gutter={[
            {
              xs: 0,
              sm: 0,
              md: 55,
              lg: 55,
            },
            { xs: 32, sm: 24, md: 32, lg: 32 },
          ]}
        >
          <Col span={24}>
            <Typography.Title level={1}>Credit Card Details</Typography.Title>
          </Col>
          <Col span={24} lg={{ span: 14 }}>
            <Row gutter={[0, 32]}>
              <Col span={24} lg={{ span: 0 }}>
                <CreditCard data={data} />
              </Col>

              <Col span={24}>
                <CreditCardViewForm />
              </Col>
              <Col span={24} lg={{ span: 0 }}>
                <CardBlock />
              </Col>
            </Row>
          </Col>
          <Col xs={{ span: 0 }} lg={{ span: 10 }}>
            <Row gutter={[0, 28]} className={'desktop-page-side'}>
              <Col span={24}>
                <CreditCard data={data} />
              </Col>
              <Col span={24}>
                <CardBlock />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </OverlayLoader>
  )
}

export default CreditCardView
